import dayjs from "dayjs";
import { DateTime } from "luxon";
import Appointment, {
  isCompleted,
  isMissedAndCannotRetake,
  isScheduled,
} from "./appointment";
import { Delivery, DeliveryStatusOptions } from "./delivery";
import { DeliveryDestinationOptions } from "./delivery-settings";
import PhoneAppointment from "./phone-appointment";
import { PrepStatus, PREP_STATUS } from "./prep-status";

export const DEFAULT_BIRTHDATE = DateTime.now().minus({ years: 15 }).toJSDate();
export const DEFAULT_RAMQEXPDATE = DateTime.now().plus({ years: 5 }).toJSDate();

export type TfaPreference = "sms" | "email";

export default interface User {
  id: number;
  ramqNumber: string;
  ramqExpDate: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  phone: string;
  email: string;
  phoneNumberConfirmed: boolean;
  profession: string;
  tfaPreference?: TfaPreference;
  address: string;
  city: string;
  zipCode: string;
  country: string;
  lang: string;
  selfDefinedGender: string;
  bornGender: string;
  lastAppointmentId: number;
  emailRemindersDisabled: boolean;
  age: number;
  hasToPay: boolean;
  appointments: Appointment[];
  phoneAppointments: PhoneAppointment[];
  lastUserQuestionnaireId: number;
  lastUserQuestionnaire?: any; // TODO: Create interface for UseQuestionnaire
  lastPrepStatus?: PrepStatus;
  prepStatuses: PrepStatus[];
  created_at: string;
}

export const isFirstTimeUser = ({ lastAppointmentId }: User): boolean => {
  return lastAppointmentId !== null && lastAppointmentId !== undefined;
};

export const getLastAppointment = ({
  appointments,
  lastAppointmentId,
}: User): Appointment | undefined => {
  return appointments.find(({ id }) => id === lastAppointmentId);
};

export const hasCompletedQuestionnaireWithoutScheduling = ({
  lastUserQuestionnaire,
  appointments,
}: User): boolean => {
  const lastQuestionnaireAppointment = appointments.find(
    ({ userQuestionnaire }) =>
      lastUserQuestionnaire && userQuestionnaire.id === lastUserQuestionnaire.id
  );
  return (
    lastUserQuestionnaire !== undefined &&
    lastUserQuestionnaire !== null &&
    !lastQuestionnaireAppointment
  );
};

export const expiredPendingQuestionnaire = (user: User): any | undefined => {
  return hasCompletedQuestionnaireWithoutScheduling(user) &&
    user.lastUserQuestionnaire!.isExpired
    ? user.lastUserQuestionnaire
    : undefined;
};

export const hasPaidLastQuestionnaire = ({
  lastUserQuestionnaire,
}: User): boolean => {
  return lastUserQuestionnaire && lastUserQuestionnaire.stripeOrder;
};

export const upcomingScreeningAppointment = (
  user: User
): Appointment | undefined => {
  const lastUserAppointment = getLastAppointment(user);
  return lastUserAppointment &&
    isScheduled(lastUserAppointment) &&
    !isMissedAndCannotRetake(lastUserAppointment)
    ? lastUserAppointment
    : undefined;
};

export const hasRefusedLastAppointmentMedicalTests = (user: User): boolean => {
  const lastAppointment = getLastAppointment(user);
  if (!lastAppointment || !isCompleted(lastAppointment)) return false;

  return lastAppointment.chosenMedicalTests.every(
    (cmt) => cmt.status === "REFUSED"
  );
};

// For the user's last appointment, check if he has an uncompleted phone appointment
export const hasFollowUpPhoneAppointmentToComplete = (user: User): boolean => {
  const lastAppointment = getLastAppointment(user);
  if (!lastAppointment) return false;
  if (!isCompleted(lastAppointment)) return false;
  if (hasRefusedLastAppointmentMedicalTests(user)) return false;

  if (
    DateTime.fromISO(lastAppointment.datetime).plus({ month: 7 }) <
    DateTime.now()
  )
    return false;

  return !lastAppointment.followUpPhoneAppointments.some(
    (phoneAppointment: PhoneAppointment) =>
      phoneAppointment.status === "COMPLETED" ||
      phoneAppointment.status === "CANCELED"
  );
};

/**
 * Function that checks whether or not a user has an upcoming PrEP eligibility phone appointment
 * @param user User
 * @returns Boolean => true if yes / false if no
 */
export const hasPrepEligibilityPA = (user: User): boolean =>
  user.phoneAppointments
    ? user.phoneAppointments.some(
        (pa) =>
          pa.type === "PREP_ELIGIBILITY" &&
          pa.status === "SCHEDULED" &&
          !pa.isMissed
      )
    : false;

/**
 * Function that checks whether a or not a user is interested in PrEP (answered yes to the "wants PrEP" question)
 * @param user User
 * @returns Boolean => true if yes / false if no
 */
export const hasPrepInterest = (user: User): boolean =>
  user.lastUserQuestionnaire?.wantsPrep;

/**
 * Function that checks whether or not a user is eligible for PrEP based on his PrEP status
 * @param user User
 * @returns Boolean => true if yes / false if no
 */
export const isPrepEligible = (user: User): boolean =>
  user.lastPrepStatus?.status === PREP_STATUS.ELIGIBLE;

/**
 * Checks if the user has any upcoming delivery to a prelib location.
 *
 * @param {User} user The user whose deliveries are being checked.
 * @param {Delivery[]} deliveries Future deliveries to check.
 * @returns {boolean} True if there is an upcoming delivery to a prelib location, false if there are none
 */
export const hasUpcomingDeliveryAtPrelib = (
  user: User,
  deliveries: Delivery[]
): boolean => {
  const upcomingScreeningApt = upcomingScreeningAppointment(user);
  if (!upcomingScreeningApt) return false;

  const now = dayjs();
  return deliveries.some(
    (delivery) =>
      now.isBefore(delivery.date) &&
      delivery.status != DeliveryStatusOptions.CANCELED &&
      delivery.status != DeliveryStatusOptions.REJECTED &&
      delivery.destination === DeliveryDestinationOptions.PRELIB_PICK_UP
  );
};

/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import Axios from "axios";
import Clinic from "../../models/clinic";
import useLanguage from "../../hooks/language";
import ENDPOINTS from "../../utils/endpoints";

import logoSrc from "../../assets/images/logo.svg";
import facebookSrc from "../../assets/images/facebook.png";
import instagramSrc from "../../assets/images/instagram.png";
import youtubeSrc from "../../assets/images/youtube.png";

import styles from "./footer.module.css";

type Schedule = {
  title: string;
  content: string;
};

const Footer: React.FunctionComponent = () => {
  const { translations } = useLanguage();
  const t = translations.footer;

  // States

  const [clinics, setClinics] = useState<Clinic[]>([]);

  // Effects

  useEffect(() => {
    fetchClinics();
  }, []);

  // Network

  const fetchClinics = async () => {
    try {
      const { data } = await Axios.get(ENDPOINTS.CLINICS, {
        params: { showNextTime: false },
      });
      setClinics(data);
    } catch (e) {
      console.log(e);
    }
  };

  // Rendering

  const renderClinicInfo = (clinic: Clinic) => {
    return (
      <div className={styles.clinicInfo} key={clinic.id}>
        <b>{clinic.name}</b>
        <br />
        <a
          target="_blank"
          href={`https://www.google.com/maps/search/${clinic.address}`}
        >
          {clinic.address}
        </a>
      </div>
    );
  };

  return (
    <div className={styles.footer}>
      <div className="container">
        <hr />

        <img alt={t.prelibAlt} className={styles.logo} src={logoSrc} />

        <div>
          <div className={styles.inner}>
            <div>
              <div className={styles.infoHolder}>
                <a href="tel:5145351475">
                  <b>(514) 535-1475</b>
                </a>
              </div>
              <div className={styles.infoHolder}>
                <a href="tel:18005144455">
                  <a href="mailto:info@prelib.com">
                    <b>info@prelib.com</b>
                  </a>
                </a>
              </div>
              {t.schedule.map(({ title, content }: Schedule) => (
                <div className={styles.schedule} key={title}>
                  <b>{title}</b>
                  <span>{content}</span>
                </div>
              ))}
            </div>

            <div>{clinics.map(renderClinicInfo)}</div>

            <div>
              <b>{t.followUs}</b>
              <br />
              <a target="_blank" href="https://www.facebook.com/centreprelib">
                <img alt={t.facebookAlt} src={facebookSrc} />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/centre.prelib/"
              >
                <img alt={t.instagramAlt} src={instagramSrc} />
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCt12sU0NRUsoMJgz4fGwWqQ"
              >
                <img alt={t.youtubeAlt} src={youtubeSrc} />
              </a>
            </div>
          </div>
          <hr />
        </div>

        <p className={styles.disclaimer}>{t.disclaimer}</p>
      </div>
    </div>
  );
};

export default Footer;

import Axios from "axios";
import { Delivery } from "models/delivery";
import { DeliverySettings } from "models/delivery-settings";
import { PrepStatus } from "models/prep-status";
import ENDPOINTS from "utils/endpoints";

// TODO: Maybe extract me somewhere else, someday?
// We have an Axios interceptor that triggers the NotificationManager any time
// a network request fails, no matter the error code. The problem with that is
// that sometimes the request fails for an expected, valid reason.
// For example, a REST server might return a 404 if it does not find a resource
// that it expected not to always be found.
const skipValidation = (statuses: number[]) => (status: number) =>
  (status >= 200 && status < 300) || statuses.includes(status);

export const getDeliverySettings = (): Promise<DeliverySettings | null> =>
  Axios.get(ENDPOINTS.DELIVERY_SETTINGS, {
    // We skip the validation for 404, because it is absolutely possible and
    // acceptable that the user has no delivery settings
    validateStatus: skipValidation([404]),
  }).then(({ status, data }) => (status === 200 ? data : null));

export const updateDeliverySettings = (
  settings: Partial<DeliverySettings>
): Promise<DeliverySettings> =>
  Axios.put(ENDPOINTS.DELIVERY_SETTINGS, settings).then(({ data }) => data);

export const createOnDemandDelivery = (
  payload: Partial<Delivery>
): Promise<Delivery> =>
  Axios.post(ENDPOINTS.DELIVERY, payload).then(({ data }) => data);

export const getDeliveries = (
  period: "PAST" | "FUTURE",
  date: string
): Promise<Delivery[]> =>
  Axios.get(ENDPOINTS.DELIVERY, { params: { period, date } }).then(
    ({ data }) => data
  );

export const updateDelivery = async (
  payload: Partial<Delivery>,
  id: number
): Promise<Delivery> =>
  Axios.put(`${ENDPOINTS.DELIVERY}/${id}`, payload).then(({ data }) => data);

export const revokePrepStatus = async (): Promise<PrepStatus> => {
  return Axios.post(ENDPOINTS.REVOKE_PREP_STATUS).then(({ data }) => data);
};

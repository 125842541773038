export default {
  LOGIN: "/login",
  EMAIL_CONFIRMATION: "/email-confirmation/:token",
  PASSWORD_FORGOTTEN: "/password-forgotten",
  PASSWORD_RESET: "/password-reset/:token",
  UNSUBSCRIBE: "/unsubscribe",
  HOME: "/home",
  TFA_INPUT: "/tfa-input",
  TFA_SETTINGS: "/tfa-settings",
  PROFILE: "/profile",
  SCREENING: "/home/screening",
  APPOINTMENTS: "/home/appointments",
  PREP: "/home/prep",
};

import React, { useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import Question, { extractQuestionError } from "../../../models/question";
import useLanguage from "../../../hooks/language";

import styles from "./tracker.module.css";

const INDEX_WIDTH = 25 + 15 + 2 * 2;
const INDEX_CONTAINER_PADDING = 20;
const TRACKER_CONTAINER_ID_PREFIX = "tracker-container-";

interface TrackerProps {
  questions: Question[];
  focusedIndex: number;
  onClickQuestion: (index: number) => void;
  showErrors?: boolean;
}

const Tracker: React.FunctionComponent<TrackerProps> = ({
  focusedIndex = 0,
  showErrors = false,
  questions,
  onClickQuestion,
}: TrackerProps) => {
  const { translations } = useLanguage();

  // TODO: Make sticky translation smoother

  // Effects

  useEffect(() => {
    const scrollPos = focusedIndex * INDEX_WIDTH + INDEX_CONTAINER_PADDING / 2;
    const scrollOptions = {
      duration: 500,
      ignoreCancelEvents: true,
      horizontal: true,
    };

    scroll.scrollTo(scrollPos, {
      ...scrollOptions,
      containerId: getTrackerId(1),
    });
    scroll.scrollTo(scrollPos, {
      ...scrollOptions,
      containerId: getTrackerId(2),
    });
  }, [focusedIndex]);

  // Helpers

  const getTrackerId = (index: number) =>
    `${TRACKER_CONTAINER_ID_PREFIX}${index}`;

  // Rendering

  const renderQuestionIndex = (question: Question, index: number) => {
    const error = extractQuestionError(question, translations);

    const shouldShowError = error !== undefined && showErrors;
    return (
      <div
        className={`${styles.indexHolder} ${
          index === focusedIndex ? styles.active : ""
        } ${index < focusedIndex ? styles.done : ""} ${
          shouldShowError ? styles.hasError : ""
        }`}
      >
        <span />
        <div onClick={() => onClickQuestion(index)}>{question.index}</div>
      </div>
    );
  };

  const renderContainer = (index: number) => (
    <div
      className={`${styles.trackerContainer} container`}
      id={getTrackerId(index)}
    >
      <div className={styles.indexList}>
        {questions.map(renderQuestionIndex)}
      </div>
    </div>
  );

  return (
    <div>
      <div className={`${styles.tracker} ${styles.fixed}`}>
        {renderContainer(1)}
      </div>
      <div className={styles.tracker}>{renderContainer(2)}</div>
    </div>
  );
};

export default Tracker;

import { createContext } from "react";
import {
  getPersistedAuthToken,
  persistAuthToken,
  getPersistedUserLanguage,
  persistUserLanguage,
} from "../utils/local-storage";
import { IAnalyticsService } from "../utils/analytics";
import { UserLanguage } from "../utils/types";
import { getTranslations } from "../translations";
import User from "../models/user";

interface AppContextInterface {
  authToken: string | null;
  setAuthToken: (token: string | null) => void;
  authUser: User | null;
  setAuthUser: (user: User | null) => void;
  language: UserLanguage;
  setLanguage: (language: UserLanguage) => void;
  translations: any; // Any is not so sexy but it's kind of legacy stuff from old code
  analytics: IAnalyticsService | null;
  setAnalytics: (service: IAnalyticsService) => void;
}

const context: AppContextInterface = {
  authToken: getPersistedAuthToken(),
  setAuthToken: (token: string | null) => persistAuthToken(token),
  authUser: null,
  setAuthUser: () => console.log("Not implemented yet"),
  language: getPersistedUserLanguage(),
  setLanguage: persistUserLanguage,
  translations: getTranslations(),
  analytics: null,
  setAnalytics: () => console.log("Not implemented"),
};

const AppContext = createContext(context);

export default AppContext;

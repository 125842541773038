import React, { useState } from "react";
import Calendar, {
  CalendarTileProperties,
  ViewCallbackProperties,
} from "react-calendar";
import { DateTime } from "luxon";
import { ChevronLeft, ChevronRight } from "react-feather";
import useLanguage from "../../../hooks/language";
import { Loader } from "react-feather";

import "react-calendar/dist/Calendar.css";
import "./calendar.css";

const DAYS_FR = ["D", "L", "M", "M", "J", "V", "S"];
const DAYS_EN = ["S", "M", "T", "W", "T", "F", "S"];
interface CalendarProps {
  minDate?: Date;
  maxDate?: Date;
  openedDates?: string[];
  jumpYears?: boolean;
  jumpMonths?: boolean;
  onCalendarNavigation?: (firstActiveDay: Date) => void;
  onDateChange?: (date: Date) => void;
}

const CalendarPicker: React.FunctionComponent<CalendarProps> = ({
  minDate,
  maxDate,
  openedDates,
  onCalendarNavigation,
  onDateChange,
}: CalendarProps) => {
  const { language } = useLanguage();

  // state

  const [value, setDate] = useState<Date>(new Date());

  // Helpers

  const isTileDisabled = (
    calendarProps: CalendarTileProperties & { activeStartDate: Date }
  ): boolean => {
    const date: DateTime = DateTime.fromJSDate(calendarProps.date);
    const _minDate = minDate ? DateTime.fromJSDate(minDate) : undefined;
    const _maxDate = maxDate ? DateTime.fromJSDate(maxDate) : undefined;

    if (_minDate && date < _minDate) return true;

    if (_maxDate && date > _maxDate) return true;

    if (!openedDates) return false;
    return !openedDates.some((d) => DateTime.fromISO(d).hasSame(date, "day"));
  };

  const getWeeksDays = (locale: string, date: Date) => {
    const dayAbbrevArray = locale === "fr" ? DAYS_FR : DAYS_EN;
    return dayAbbrevArray[date.getDay()];
  };

  // Handlers

  const _onDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) return;
    if (onDateChange) onDateChange(date);
    setDate(date);
  };

  const _onActiveDateStartChange = (activeDateInfo: ViewCallbackProperties) => {
    if (onCalendarNavigation)
      onCalendarNavigation(activeDateInfo.activeStartDate);
  };

  // Rendering

  const loading = !openedDates;

  const renderTileContent = (
    tileProps: CalendarTileProperties & { activeStartDate: Date }
  ) => {
    const monthNumber = tileProps.activeStartDate.getMonth();
    if (tileProps.date.getMonth() === monthNumber) {
      return (
        <div
          className={`calendar-day-status ${
            isTileDisabled(tileProps) ? "no-se-puede" : ""
          }`}
        />
      );
    } else return <Loader className="spinorama" />;
  };

  return (
    <Calendar
      showNavigation={!loading}
      className={"calendar-overwrite"}
      minDate={minDate}
      maxDate={maxDate}
      maxDetail={"month"}
      minDetail={"month"}
      showNeighboringMonth={false}
      onActiveStartDateChange={_onActiveDateStartChange}
      prevLabel={
        <ChevronLeft
          className={loading ? "disabled" : ""}
          size={30}
          color="var(--primary-black-color)"
        />
      }
      prev2Label={null}
      nextLabel={
        <ChevronRight
          className={loading ? "disabled" : ""}
          size={30}
          color="var(--primary-black-color)"
        />
      }
      next2Label={null}
      tileDisabled={isTileDisabled}
      view={"month"}
      formatShortWeekday={getWeeksDays}
      locale={language}
      tileContent={renderTileContent}
      onChange={_onDateChange}
      value={value}
    />
  );
};

export default CalendarPicker;

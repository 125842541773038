import { useLastAppointment } from "context/last-appointment";
import useAnalytics from "hooks/analytics";
import React, { useState } from "react";
import { ChevronDown, User } from "react-feather";
import { useHistory } from "react-router-dom";
import { Button, Popover } from "../";
import logoSrc from "../../assets/images/logo.svg";
import useAuthToken from "../../hooks/auth-token";
import useAuthUser from "../../hooks/auth-user";
import useLanguage from "../../hooks/language";
import useScroll from "../../hooks/scroll";
import {
  hasCompletedQuestionnaireWithoutScheduling,
  hasFollowUpPhoneAppointmentToComplete,
  upcomingScreeningAppointment,
} from "../../models/user";
import ROUTES from "../../utils/routes";
import { LanguagePicker } from "../index";
import styles from "./header.module.css";

const PRELIB_WEBSITE_URL = "https://www.prelib.com";

const Header: React.FunctionComponent = () => {
  const [authUser] = useAuthUser();
  const {
    hasToPayLastMissedAppointment,
    setShowPaymentForm,
  } = useLastAppointment();
  const setAuthToken = useAuthToken()[1];
  const { scrollY } = useScroll();
  const { push } = useHistory();
  const { translations } = useLanguage();
  const t = translations.homePage.topMenu;
  const [analytics] = useAnalytics();

  // State

  const [popoverShown, setPopoverShown] = useState(false);

  // Handlers

  const onClickScreeningButton = () => {
    if (hasToPayLastMissedAppointment) setShowPaymentForm(true);
    else push(ROUTES.SCREENING);
  };

  // Rendering

  const upcomingAppointment =
    authUser && upcomingScreeningAppointment(authUser);

  const userHasFollowUpPhoneAppointmentToComplete =
    authUser && hasFollowUpPhoneAppointmentToComplete(authUser);

  const screeningButtonText =
    authUser && hasCompletedQuestionnaireWithoutScheduling(authUser)
      ? t.completeScreeningButtonText
      : t.makeScreeningButtonText;

  const onSignOut = () => {
    analytics?.cdp()?.disconnectUser();
    setAuthToken(null);
  };

  const renderUserButton = () => {
    if (!authUser) return;
    return (
      <div style={{ position: "relative" }}>
        <button
          className={styles.profileButton}
          onClick={() => setPopoverShown(!popoverShown)}
        >
          <div>
            <User />
          </div>
          <span className={styles.desktopOnly}>{authUser.firstName}</span>
          <ChevronDown />
        </button>
        <Popover
          align="right"
          position="bottom"
          shown={popoverShown}
          onChange={setPopoverShown}
        >
          <div className={styles.popoverContent}>
            {authUser &&
              !upcomingAppointment &&
              !userHasFollowUpPhoneAppointmentToComplete && (
                <button
                  className={styles.mobileOnly}
                  onClick={onClickScreeningButton}
                >
                  {screeningButtonText}
                </button>
              )}
            <button onClick={() => push(ROUTES.PROFILE)}>
              {t.updateProfile}
            </button>
            <button className="danger" onClick={onSignOut}>
              {t.disconnectButtonText}
            </button>
          </div>
        </Popover>
      </div>
    );
  };

  return (
    <div className={`${styles.header} ${scrollY > 50 ? styles.bgWhite : ""}`}>
      <a href={authUser ? ROUTES.HOME : PRELIB_WEBSITE_URL}>
        <img className={styles.logo} src={logoSrc} />
      </a>
      <div>
        <LanguagePicker />
        {authUser &&
          !upcomingAppointment &&
          !userHasFollowUpPhoneAppointmentToComplete && (
            <Button
              className={styles.screeningButton}
              onClick={onClickScreeningButton}
            >
              {screeningButtonText}
            </Button>
          )}
        {authUser && renderUserButton()}
      </div>
    </div>
  );
};

export default Header;

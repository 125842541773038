import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { Loader } from "react-feather";
import {
  Button,
  Checkbox,
  PersonalRecommendations,
} from "../../../../components";
import useAuthUser from "../../../../hooks/auth-user";
import useLanguage from "../../../../hooks/language";
import ENDPOINTS from "../../../../utils/endpoints";
import ROUTES from "../../../../utils/routes";
import Appointment from "../../../../models/appointment";
import SuggestedMedicalTest, {
  sortSuggestedMedicalTest,
} from "../../../../models/suggested-medical-test";
import { getLocaleName } from "../../../../models/medical-test";

import styles from "./recommendations.module.css";

interface RecommendationsProps {
  upcomingAppointment?: Appointment;
  selectedMedicalTests?: SuggestedMedicalTest[];
  onRestartQuestionnaireClick: () => void;
  onSelectMedicalTests: (test: SuggestedMedicalTest[]) => void;
}

const Recommendations: React.FunctionComponent<RecommendationsProps> = ({
  upcomingAppointment,
  selectedMedicalTests: preSelectedMedicalTests,
  onRestartQuestionnaireClick,
  onSelectMedicalTests,
}: RecommendationsProps) => {
  const { push } = useHistory();
  const [authUser, setAuthUser] = useAuthUser();
  const { translations, language } = useLanguage();
  const t = translations.recommendationsSection;

  // States
  const [suggestedMedicalTests, setSuggestedMedicalTests] = useState<
    null | SuggestedMedicalTest[]
  >(null);
  const [selectedSampleTypes, setSelectedSampleTypes] = useState<string[]>(
    preSelectedMedicalTests ? generateSampleTypes(preSelectedMedicalTests) : []
  );
  const [isLoading, setLoading] = useState(false);

  const [selectedBloodTests, setSelectedBloodTests] = useState<string[]>(
    preSelectedMedicalTests ? generateBloodTests(preSelectedMedicalTests) : []
  );

  // Effects

  useEffect(() => {
    if (!authUser || !authUser.lastUserQuestionnaire) return;
    fetchRecommendations(authUser.lastUserQuestionnaire.id);
  }, [authUser]);

  useEffect(() => {
    if (!suggestedMedicalTests) return;
    if (!preSelectedMedicalTests) {
      setSelectedSampleTypes(generateSampleTypes(suggestedMedicalTests));
      setSelectedBloodTests(generateBloodTests(suggestedMedicalTests));
    }
  }, [suggestedMedicalTests]);

  // Handlers

  const toggleTest = (testType: string) => {
    const index = selectedSampleTypes.indexOf(testType);

    const sampleTypes = [...selectedSampleTypes];
    if (index > -1) {
      sampleTypes.splice(index, 1);
    } else {
      sampleTypes.push(testType);
    }

    setSelectedSampleTypes(sampleTypes);
  };

  const toggleBloodTest = (testName: string) => {
    const index = selectedBloodTests.indexOf(testName);
    const bloodTests = [...selectedBloodTests];
    if (index > -1) bloodTests.splice(index, 1);
    else bloodTests.push(testName);
    setSelectedBloodTests(bloodTests);
  };
  const toggleAllBloodTest = (bloodTest: SuggestedMedicalTest[]) => {
    let newSelectedBloodTests: string[] = [];
    if (selectedBloodTests.length != bloodTest.length && suggestedMedicalTests)
      newSelectedBloodTests = generateBloodTests(suggestedMedicalTests);
    setSelectedBloodTests(newSelectedBloodTests);
  };

  const onSubmit = () => {
    if (upcomingAppointment) {
      updateAppointment();
    } else {
      onSelectMedicalTests(getSelectedTests());
    }
  };

  // Helpers
  function extractTypeFromTest(tests: SuggestedMedicalTest[]) {
    const mappedTests = new Map();
    tests.forEach((test: SuggestedMedicalTest) => {
      if (test.medicalTest.sampleType != "BLOOD") {
        if (mappedTests.has(test.medicalTest.sampleType)) {
          mappedTests.set(test.medicalTest.sampleType, [
            ...mappedTests.get(test.medicalTest.sampleType),
            test,
          ]);
        } else mappedTests.set(test.medicalTest.sampleType, [test]);
      }
    });
    return mappedTests;
  }

  function extractBloodTests(tests: SuggestedMedicalTest[]) {
    const bloodTests: SuggestedMedicalTest[] = [];
    tests.forEach((test: SuggestedMedicalTest) => {
      if (test.medicalTest.sampleType == "BLOOD") bloodTests.push(test);
    });
    return bloodTests;
  }

  const testTypeTranslate = (testType: string) => {
    const validT = testType.toLowerCase() + "Screening";
    return t[validT];
  };

  const bloodTestTranslate = (test: string) => {
    const bloodTestMedical = suggestedMedicalTests?.find(
      (suggestedTest) => suggestedTest.medicalTest.name == test
    )?.medicalTest;
    if (bloodTestMedical) return getLocaleName(bloodTestMedical, language);
    return null;
  };

  const getSelectedTests = () => {
    if (suggestedMedicalTests) {
      return suggestedMedicalTests?.filter((test) => {
        if (test.medicalTest.sampleType != "BLOOD")
          return selectedSampleTypes.includes(test.medicalTest.sampleType);
        else return selectedBloodTests.includes(test.medicalTest.name);
      });
    }
    return [];
  };

  function generateSampleTypes(from: SuggestedMedicalTest[]) {
    const preSelectedCat = extractTypeFromTest(from).keys();
    return Array.from(preSelectedCat);
  }

  function generateBloodTests(from: SuggestedMedicalTest[]) {
    const preSelectedTests = extractBloodTests(from);
    const preSelectedTestsStr = preSelectedTests.map(
      (test) => test.medicalTest.name
    );
    return preSelectedTestsStr;
  }
  // Network

  const updateAppointment = async () => {
    if (!authUser || !upcomingAppointment) return;

    setLoading(true);

    const { lastUserQuestionnaireId: userQuestionnaireId } = authUser;
    const chosenMedicalTests = getSelectedTests().map(({ medicalTest }) => ({
      medicalTestId: medicalTest.id,
    }));
    const body = {
      userQuestionnaireId,
      chosenMedicalTests,
    };

    const { id } = upcomingAppointment;

    try {
      await Axios.put(ENDPOINTS.UPDATE_APPOINTMENT_TESTS(id), body);
      const { data } = await Axios.get(ENDPOINTS.ME);
      setAuthUser(data);
      NotificationManager.success(t.appointmentUpdated);
      push(ROUTES.HOME);
    } catch {
      setLoading(false);
    }
  };

  const fetchRecommendations = async (userQuestionnaireId: number) => {
    const { data } = await Axios.get(
      ENDPOINTS.MEDICAL_TESTS_RECOMMENDATIONS(userQuestionnaireId)
    );
    setSuggestedMedicalTests(sortSuggestedMedicalTest(data, language));
  };

  // Rendering

  const renderIndividualTest = (test: SuggestedMedicalTest) => (
    <li key={test.id}>
      <Checkbox
        checked={selectedSampleTypes.includes(test.medicalTest.sampleType)}
        disabled={true}
      />
      {getLocaleName(test.medicalTest, language)}
    </li>
  );

  const renderIndividualBloodTest = (test: string) => {
    return (
      <li key={test}>
        <Checkbox
          checked={selectedBloodTests.includes(test)}
          onChange={() => toggleBloodTest(test)}
        />
        {bloodTestTranslate(test)}
      </li>
    );
  };

  const renderBloodTestsArr = (bloodTest: SuggestedMedicalTest[]) => {
    return (
      <div>
        <li key="BLOODTESTS">
          <Checkbox
            checked={bloodTest.length == selectedBloodTests.length}
            onChange={() => toggleAllBloodTest(bloodTest)}
          />
          {testTypeTranslate("BLOOD")}
        </li>
        <ul>
          {bloodTest.map((test: SuggestedMedicalTest) =>
            renderIndividualBloodTest(test.medicalTest.name)
          )}
        </ul>
      </div>
    );
  };

  const renderMappedTests = (
    mappedTests: Map<string, SuggestedMedicalTest[]>
  ) => {
    const renderedMap: JSX.Element[] = [];
    mappedTests.forEach((value, key) => {
      renderedMap.push(
        <div key={key}>
          <li key={key}>
            <Checkbox
              checked={selectedSampleTypes.includes(key)}
              onChange={() => toggleTest(key)}
            />
            {testTypeTranslate(key)}
          </li>
          <ul>
            {value.map((test: SuggestedMedicalTest) =>
              renderIndividualTest(test)
            )}
          </ul>
        </div>
      );
    });
    return renderedMap;
  };

  const renderTest = (tests: SuggestedMedicalTest[]) => {
    const mappedTests = extractTypeFromTest(tests);
    return renderMappedTests(mappedTests);
  };

  const renderBloodTests = (tests: SuggestedMedicalTest[]) => {
    const bloodTestsArr = extractBloodTests(tests);
    return renderBloodTestsArr(bloodTestsArr);
  };

  return (
    <div className={`${styles.holder} container`}>
      <h2>{t.header}</h2>
      <p>{t.medicalTestsPresentation}</p>
      {!suggestedMedicalTests && (
        <div>
          <Loader className="spinorama" />
          <br />
          <br />
        </div>
      )}
      {suggestedMedicalTests && (
        <ul className={styles.list}>
          {renderTest(suggestedMedicalTests)}
          {renderBloodTests(suggestedMedicalTests)}
        </ul>
      )}
      <br />
      <b>{t.customRecommendationsPresentation}</b>
      {authUser && authUser.lastUserQuestionnaire && (
        <PersonalRecommendations
          userQuestionnaire={authUser.lastUserQuestionnaire}
        />
      )}
      <div className={styles.infoHolder}>
        <h5>{t.importantHeader}</h5>
        <p>{t.importantSymptoms}</p>
        <p>{t.importantNewEvaluation}</p>
        <p>{t.importantFee}</p>
      </div>
      <p className="disclaimer">{t.extraCharges}</p>
      <div className="dual-button-holder flex-end">
        <Button type="secondary" onClick={onRestartQuestionnaireClick}>
          {t.restartQuestionnaire}
        </Button>
        <Button
          loading={isLoading}
          disabled={selectedSampleTypes.length === 0 || !suggestedMedicalTests}
          onClick={onSubmit}
        >
          {upcomingAppointment ? t.updateTestSelection : t.choseDateButtonText}
        </Button>
      </div>
    </div>
  );
};

export default Recommendations;

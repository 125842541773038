import * as _ from "lodash";
import { ConfigProvider } from "antd";
import frCA from "antd/locale/fr_CA";
import enUS from "antd/locale/en_US";
import { Card } from "components/card";
import Button from "components/button";
import { DatePicker } from "components/date-picker";
import Field from "components/field";
import Option from "components/select/option";
import Select from "components/select";
import useLanguage from "hooks/language";
import { useUpdateSettings } from "../../utils/context";
import {
  useDeliverySettingsForm,
  in72Hours,
  getSelectionKey,
  getISOString,
  getEventValue,
} from "../../utils/utils";
import styles from "./delivery-settings-form.module.css";

export const DeliverySettingsForm = () => {
  const {
    translations: {
      prepSection: { deliverySettingsForm: t },
    },
    language,
  } = useLanguage();

  const { form, setField } = useDeliverySettingsForm();
  const updateSettings = useUpdateSettings();
  const submit = () => updateSettings && updateSettings(form);

  const frequencies: Record<string, { title: string }> = t.frequency.options;
  const destinations: Record<string, { title: string }> = t.destination.options;

  return (
    <ConfigProvider locale={language === "en" ? enUS : frCA}>
      <Card className={styles.deliverySettingsForm}>
        <Card.Header>
          <h3>{t.title}</h3>
        </Card.Header>
        <Card.Body>
          <form>
            <p>
              {t.subtitles.map((subtitle: string) => (
                <>
                  {subtitle}
                  <br />
                </>
              ))}
            </p>

            <label>{t.date.label}</label>
            <DatePicker
              showNow={false}
              placeholder={t.date.placeholder}
              disabledDate={(date) => date.isBefore(in72Hours())}
              onChange={_.flow(getISOString, setField("firstDeliveryDate"))}
            />
            <span className={styles.warning}>{t.date.warning}</span>

            <label className={styles.mt20}>{t.frequency.label}</label>
            <Select
              placeholder={t.frequency.placeholder}
              onSelect={_.flow(getSelectionKey, setField("frequency"))}
            >
              {Object.entries(frequencies).map(([value, label]) => {
                return (
                  <Option key={value} value={label.title}>
                    {label.title}
                  </Option>
                );
              })}
            </Select>

            <label className={styles.mt20}>{t.destination.label}</label>
            <Select
              placeholder={t.destination.placeholder}
              onSelect={_.flow(getSelectionKey, setField("destination"))}
            >
              {Object.entries(destinations).map(([value, label]) => (
                <Option key={value} value={label.title}>
                  {label.title}
                </Option>
              ))}
            </Select>

            <label className={styles.mt20}>{t.address.label}</label>
            <Field
              placeholder={t.address.placeholder}
              value={form.address}
              onChange={_.flow(getEventValue, setField("address"))}
            />

            <div>
              <label className={styles.mt20}>{t.city.label}</label>
              <Field
                placeholder={t.city.placeholder}
                value={form.city}
                onChange={_.flow(getEventValue, setField("city"))}
              />

              <label className={styles.mt20}>{t.zipCode.label}</label>
              <Field
                placeholder={t.zipCode.placeholder}
                value={form.zipCode}
                onChange={_.flow(getEventValue, setField("zipCode"))}
              />

              <label className={styles.mt20}>{t.province.label}</label>
              <Field
                placeholder={t.province.placeholder}
                disabled
                value="QC"
                onChange={_.flow(getEventValue, setField("province"))}
              />
            </div>

            <Button onClick={submit}>{t.activateDeliveryCTA}</Button>
          </form>
        </Card.Body>
      </Card>
    </ConfigProvider>
  );
};

import { DateTime } from "luxon";
import Appointment, {
  isUpcoming as isUpcomingAppointment,
} from "../models/appointment";
import PhoneAppointment, {
  isUpcoming as isUpcomingPhoneAppointment,
} from "../models/phone-appointment";

export const isAppointment = (
  app: Appointment | PhoneAppointment
): app is Appointment => {
  return "userQuestionnaire" in app;
};

export const isUpcoming = (app: Appointment | PhoneAppointment): boolean => {
  if (isAppointment(app)) {
    return isUpcomingAppointment(app);
  } else {
    return isUpcomingPhoneAppointment(app);
  }
};

export const getAppointmentDate = (
  app: Appointment | PhoneAppointment
): DateTime | null => {
  const dateString = isAppointment(app) ? app.datetime : app.startAt;
  return dateString ? DateTime.fromISO(dateString) : null;
};

export const sortAppointments = (
  a: Appointment | PhoneAppointment,
  b: Appointment | PhoneAppointment,
  order: "asc" | "desc" = "asc"
): number => {
  const da = getAppointmentDate(a);
  const db = getAppointmentDate(b);
  if (!da) return 1;
  if (!db) return -1;

  if (order === "asc") return da > db ? 1 : -1;
  return da > db ? -1 : 1;
};

export const appointmentWithSatisfactionQuestionnairePrompt = (
  appointments: Appointment[]
): Appointment | undefined => {
  return appointments.find(
    (x) =>
      x.canTakeSatisfactionQuestionnaire === true && x.resultsSeen === false
  );
};

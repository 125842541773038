import { CollapseProps } from "antd";
import { usePastDeliveries } from "../../utils/context";
import { CustomCollapse } from "../components/custom-collapse";
import { collapseItem } from "../components/collapse-item";
import { TabHeader } from "../components/tab-header";

export const PastDeliveries = (): JSX.Element | null => {
  const pastDeliveries = usePastDeliveries();
  if (!pastDeliveries) return null;

  // Derived states
  const items: CollapseProps["items"] = [];
  pastDeliveries.forEach((delivery, index) => {
    items.push(collapseItem(delivery, String(index + 1)));
  });

  return (
    <div>
      {pastDeliveries && pastDeliveries.length > 0 && <TabHeader />}
      <CustomCollapse items={items} />
    </div>
  );
};

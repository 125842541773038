import React, { useState, useEffect } from "react";
import { Tooltip } from "antd";
import styles from "./tabs.module.css";

export interface TabInterface {
  content: React.ReactNode;
  title: React.ReactNode;
  disabled?: boolean;
  reason?: string;
}

interface TabsInterface {
  tabs: TabInterface[];
  className?: string;
  currentIndex?: number;
  onChange?: (tab: TabInterface, index: number) => void;
}

const Tabs: React.FunctionComponent<TabsInterface> = ({
  tabs,
  className,
  currentIndex = 0,
  onChange,
}: TabsInterface) => {
  // States

  const [_currentIndex, setCurrentIndex] = useState(currentIndex);

  // Effects

  useEffect(() => {
    setCurrentIndex(currentIndex);
  }, [currentIndex]);

  useEffect(() => {
    if (onChange) onChange(tabs[_currentIndex], _currentIndex);
  }, [_currentIndex]);

  useEffect(() => {
    if (tabs.length <= _currentIndex) setCurrentIndex(0);
  }, [tabs]);

  // Rendering

  let rootClass = styles.holder;
  if (className) rootClass += ` ${className}`;

  const tabContents = tabs.map(({ content }: TabInterface, index) => (
    <div key={index}>{content}</div>
  ));

  return (
    <div className={rootClass}>
      <div className={`${styles.containerOverride} container`}>
        <div className={styles.header}>
          {tabs.map(({ title, disabled, reason }, index) => (
            <Tooltip
              key={index}
              title={disabled && reason}
              placement="bottomRight"
            >
              <button
                onClick={() => (!disabled ? setCurrentIndex(index) : {})}
                className={`${_currentIndex === index ? styles.active : ""} ${
                  disabled ? styles.disabled : ""
                }`}
              >
                <h4>{title}</h4>
              </button>
            </Tooltip>
          ))}
        </div>
      </div>
      <div>{tabContents[_currentIndex]}</div>
    </div>
  );
};

export default Tabs;

import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";

import styles from "./accordion.module.css";
import { ChevronDown, ChevronUp } from "react-feather";

interface AccordionProps {
  header: React.ReactNode;
  defaultOpen?: boolean;
  children?: React.ReactNode;
}

const Accordion: React.FunctionComponent<AccordionProps> = ({
  header,
  defaultOpen = false,
  children,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen]);

  return (
    <div>
      <div className={styles.accordionHeader} onClick={toggleAccordion}>
        {header}
        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </div>
      <CSSTransition
        in={isOpen}
        timeout={500}
        classNames={{
          enter: styles.enter,
          enterActive: styles.enterActive,
          enterDone: styles.enterDone,
          exit: styles.exit,
          exitActive: styles.exitActive,
        }}
        unmountOnExit
        mountOnEnter
      >
        <div className={styles.accordionContent}>
          <p>{children}</p>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Accordion;

import React, { CSSProperties, useEffect, useState } from "react";
import Clinic from "../../models/clinic";
import Appointment from "../../models/appointment";
import ClinicCard from "./clinic-card";
import styles from "./clinic-list.module.css";
import AppointmentDatetimePicker from "../appointment-datetime-picker";
import { ChevronDown, ChevronUp } from "react-feather";
import { CSSTransition } from "react-transition-group";
import { Hr } from "pages/home/screening/date-selection";
import LoadingIcon from "components/loading-icon";

interface ClinicListProps {
  clinics: Clinic[];
  appointment?: Appointment;
  hasDateSelect?: boolean;
  isVisible?: boolean;
  onClinicSelect?: (clinicId: number | null) => void;
  onDateSelect?: (datetime: string | null) => void;
  style?: CSSProperties;
  startDate?: string;
}

const ClinicList: React.FunctionComponent<ClinicListProps> = ({
  clinics,
  appointment,
  hasDateSelect = false,
  onClinicSelect,
  onDateSelect,
  style,
  startDate,
}: ClinicListProps) => {
  // State
  const [selectedClinicId, setSelectedClinicId] = useState<number | null>(null);

  // Effects

  useEffect(() => {
    if (onClinicSelect) onClinicSelect(selectedClinicId);
  }, [selectedClinicId]);

  //Handler
  const setIdx = (index: number) => {
    index === selectedClinicId
      ? setSelectedClinicId(null)
      : setSelectedClinicId(index);
  };

  const renderChevron = (index: number) => {
    return index === selectedClinicId ? <ChevronUp /> : <ChevronDown />;
  };

  const renderClinic = (clinic: Clinic) => {
    return (
      <div className="d-flex flex-column" key={clinic.id} style={style}>
        <div
          className={styles.clinicListContainer}
          onClick={() => setIdx(clinic.id)}
        >
          <ClinicCard
            clinic={clinic}
            hasDateSelection={hasDateSelect}
            isVisible={hasDateSelect && selectedClinicId === clinic.id}
          />
          {hasDateSelect && renderChevron(clinic.id)}
        </div>
        {hasDateSelect && (
          <CSSTransition
            in={selectedClinicId === clinic.id}
            timeout={500}
            classNames={{
              enter: styles.enter,
              enterActive: styles.enterActive,
              enterDone: styles.enterDone,
              exit: styles.exit,
              exitActive: styles.exitActive,
            }}
            unmountOnExit
            mountOnEnter
          >
            <AppointmentDatetimePicker
              clinicId={clinic.id}
              appointment={appointment}
              onSelectDate={onDateSelect}
              startDate={startDate ?? undefined}
            />
          </CSSTransition>
        )}
        <Hr />
      </div>
    );
  };
  return (
    <>
      {clinics.length === 0 || !clinics ? (
        <div className={styles.loadingIconContainer}>
          <LoadingIcon />
        </div>
      ) : (
        <div className={styles.clinicContainer}>
          {clinics.map(renderClinic)}
        </div>
      )}
    </>
  );
};

export default ClinicList;

import React, { useState, useEffect } from "react";
import { XCircle } from "react-feather";

import styles from "./modal.module.css";

interface ModalProps {
  children?: React.ReactNode;
  className?: string;
  visible?: boolean;
  title?: string;
  subtitle?: string;
  isLoading?: boolean;
  onVisibilityChange?: (visible: boolean) => void;
}

const Modal: React.FunctionComponent<ModalProps> = ({
  children,
  title,
  subtitle,
  className,
  visible = false,
  isLoading = false,
  onVisibilityChange,
}: ModalProps) => {
  // States

  const [_visible, _setVisble] = useState(visible);

  // Effects

  useEffect(() => {
    if (onVisibilityChange) onVisibilityChange(_visible);
  }, [_visible]);

  useEffect(() => {
    _setVisble(visible);
  }, [visible]);

  // Rendering

  let rootClass = styles.modal;
  if (className) rootClass += ` ${className}`;

  return (
    <div
      className={`${styles.background} ${_visible ? styles.visible : ""}`}
      onClick={() => !isLoading && _setVisble(false)}
    >
      <div className={rootClass} onClick={(e) => e.stopPropagation()}>
        <div className={styles.header}>
          <div className="d-flex flex-column">
            <span>{title}</span>
            {subtitle && <span>{subtitle}</span>}
          </div>
          <button onClick={() => _setVisble(false)} disabled={isLoading}>
            <XCircle />
          </button>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;

import { useContext } from "react";
import AppContext from "../context/app";
import { IAnalyticsService } from "../utils/analytics";

type AnalyticsHook = [
  IAnalyticsService | null,
  (service: IAnalyticsService) => void
];

const useAnalytics = (): AnalyticsHook => {
  const { analytics, setAnalytics } = useContext(AppContext);

  return [analytics, setAnalytics];
};

export default useAnalytics;

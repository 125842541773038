import { DateTime } from "luxon";
import PhoneAppointment from "models/phone-appointment";

const ENDPOINTS = {
  LOGIN: "/auth/login",
  SIGNUP: "/users",
  RESEND_CONFIRMATION: "/auth/email/resend",
  CONFIRM_EMAIL: "/auth/email",
  REQUEST_TFA_CODE: "/auth/tfa/resend",
  VERIFY_TFA: "/auth/tfa/validate",
  REQUEST_RESET_PASSWORD_EMAIL: "/auth/password/email",
  RESET_PASSWORD: "/auth/password/reset",
  REQUEST_PHONE_CONFIRMATION_CODE: "/auth/phone/send",
  CONFIRM_PHONE_NUMBER: "/auth/phone/confirm",
  SET_TFA_SETTINGS: "/auth/tfa/setup",
  UNSUBSCRIBE: (token: string): string => `/unsubscribe?token=${token}`,
  QUESTIONNAIRE: (id: number): string => `/questionnaires/${id}`,
  ME: "/users/me",
  APPOINTMENTS: "/appointments",
  APPOINTMENT: (id: number): string => `/appointments/${id}`,
  APPOINTMENT_AVAILABLE_DATE: (
    year: number,
    month: number,
    clinicId: number | undefined
  ): string =>
    clinicId != null
      ? `/schedule-entry/available-dates?type=appointment&year=${year}&month=${month}&clinicId=${clinicId}`
      : `/schedule-entry/available-dates?type=appointment&year=${year}&month=${month}`,
  APPOINTMENT_AVAILABLE_TIME: (
    date: Date,
    clinicId: number | undefined
  ): string => {
    const isoDate = DateTime.fromJSDate(date).toISODate();
    return clinicId != null
      ? `/schedule-entry/available-start-times?type=appointment&date=${isoDate}&clinicId=${clinicId}`
      : `/schedule-entry/available-start-times?type=appointment&date=${isoDate}`;
  },
  PHONE_APPOINTMENT_AVAILABLE_DATE: (
    year: number,
    month: number,
    { id }: PhoneAppointment
  ): string =>
    `/schedule-entry/available-dates?type=phone-appointment&year=${year}&month=${month}&phoneAppointmentId=${id}`,
  PHONE_APPOINTMENT_AVAILABLE_TIME: (
    date: Date,
    { id }: PhoneAppointment
  ): string => {
    const isoDate = DateTime.fromJSDate(date).toISODate();
    return `/schedule-entry/available-start-times?type=phone-appointment&date=${isoDate}&phoneAppointmentId=${id}`;
  },
  PHARMACIST_APPOINTMENT_AVAILABLE_DATE: (
    year: number,
    month: number,
    phoneAppointmentId?: number | undefined
  ): string =>
    phoneAppointmentId != null
      ? `/schedule-entry/available-dates?type=pharmacist-appointment&year=${year}&month=${month}&phoneAppointmentId=${phoneAppointmentId}`
      : `/schedule-entry/available-dates?type=pharmacist-appointment&year=${year}&month=${month}`,
  PHARMACIST_APPOINTMENT_AVAILABLE_TIME: (
    date: Date,
    phoneAppointmentId?: number | undefined
  ): string => {
    const isoDate = DateTime.fromJSDate(date).toISODate();
    return phoneAppointmentId != null
      ? `/schedule-entry/available-start-times?type=pharmacist-appointment&date=${isoDate}&phoneAppointmentId=${phoneAppointmentId}`
      : `/schedule-entry/available-start-times?type=pharmacist-appointment&date=${isoDate}`;
  },
  USER_SATISFACTION_QUESTIONNAIRE: "/user-satisfaction-questionnaires",
  BOOK_RETAKE_APPOINTMENT: "/appointments/retake",
  REJECT_APPOINTMENT_RETAKE: (id: number): string =>
    `/appointments/${id}/reject-retake`,
  SCHEDULE_PHONE_APPOINTMENT: (id: number): string =>
    `/phone-appointments/${id}`,
  REJECT_PHONE_APPOINTMENT: (id: number): string =>
    `/phone-appointments/${id}/reject`,
  VALIDATE_COUPON: (couponName: string): string => `/coupons/${couponName}`,
  USER_QUESTIONNAIRE: "user-questionnaires",
  PAY_USER_QUESTIONNAIRE: (id: number): string =>
    `/user-questionnaires/${id}/pay`,
  MEDICAL_TESTS_RECOMMENDATIONS: (userQuestionnaireId: number): string =>
    `/user-questionnaires/${userQuestionnaireId}/medical-tests`,
  UPDATE_APPOINTMENT_TESTS: (id: number): string =>
    `/appointments/${id}/chosen-medical-tests`,
  RESCHEDULE_APPPOINTEMENT_FEES: (id: number): string =>
    `/appointments/${id}/with-fees`,
  CLINICS: "/clinic",
  PAY_MISSED_APPPOINTEMENT_FEES: (id: number): string =>
    `/appointments/${id}/missed-appointment-fees`,
  DELIVERY: "/delivery",
  DELIVERY_SETTINGS: "/delivery-settings",
  REVOKE_PREP_STATUS: "/prep/status",
};

export default ENDPOINTS;

export enum UserActionEvent {
  EmailVerificationInitiated = "Email Verification Initiated",
  EmailVerificationCompleted = "Email Verification Completed",
  LoginInitiated = "Login Initiated",
  MFASetupInitiated = "MFA Setup Initiated",
  MFASetupCompleted = "MFA Setup Completed",
  MFAVerificationCompleted = "MFA Verification Completed",
  PasswordReset = "Password Reset",
  PasswordResetRequested = "Password Reset Requested",
  PhoneVerificationInitiated = "Phone Verification Initiated",
  PhoneVerificationCompleted = "Phone Verification Completed",
  ProfileUpdated = "Profile Updated",
  ScreeningAppoinmentBooked = "Screening Appointment Booked",
  ScreeningAppoinmentCancelled = "Screening Appointment Cancelled",
  ScreeningAppoinmentRescheduled = "Screening Appointment Rescheduled",
  ScreeningInformationSubmitted = "Screening Information Submitted",
  ScreeningMedicalEvaluationSubmitted = "Screening Medical Evaluation Submitted",
  ScreeningPrepInterestSubmitted = "Screening Prep Interest Submitted",
  ScreeningPrepAppointmentBooked = "Screening Prep Appointment Booked",
  ScreeningPrepAppointmentCancelled = "Screening Prep Appointment Cancelled",
  ScreeningPrepAppointmentDeclined = "Screening Prep Appointment Declined",
  ScreeningPrivacyConsentAccepted = "Screening Privacy Consent Accepted",
  ScreeningPrivacyConsentDeclined = "Screening Privacy Consent Declined",
  ScreeningRequestedInitiated = "Screening Request Initiated",
  SignUpInitiated = "Sign Up Initiated",
  SignUpCompleted = "Sign Up Completed",
}

export interface PrepStatus {
  id: number;
  userId: number;
  status: PREP_STATUS;
  justification?: string;
  pharmacist: Pharmacist | null;
  created_at: string;
  eligibilityEndDate: string | null;
}

export enum PREP_STATUS {
  PENDING = "PENDING",
  ELIGIBLE = "ELIGIBLE",
  INELIGIBLE = "INELIGIBLE",
  REVOKED = "REVOKED",
  EXPIRED = "EXPIRED",
}

interface Pharmacist {
  first_name: string;
  last_name: string;
}

import Axios from "axios";
import useAnalytics from "hooks/analytics";
import React, { ChangeEvent, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useLocation } from "react-router-dom";
import { UserActionEvent } from "utils/analytics/events";
import signupIconSrc from "../../assets/images/signup-icon.svg";
import { Button, Checkbox, Field, PasswordValidator } from "../../components";
import useLanguage from "../../hooks/language";
import { TfaPreference } from "../../models/user";
import ENDPOINTS from "../../utils/endpoints";
import { EMAIL_REGEX, PHONE_NUMBER_REGEX } from "../../utils/validation";
import styles from "./login.module.css";

interface SignupFormProps {
  setLoginMode: () => void;
}

const SignupForm: React.FunctionComponent<SignupFormProps> = ({
  setLoginMode,
}: SignupFormProps) => {
  const { search } = useLocation();
  const { translations } = useLanguage();
  const t = translations.login;
  const hotmailWarningMessage = (
    <>
      <b>{t.warning}</b>: {t.hotmailWarning}: <i>no-reply@prelib.ca</i>
    </>
  );
  const [analytics] = useAnalytics();

  // States

  const [submitted, setSubmitted] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [tfaPreference, setTfaPreference] = useState<
    undefined | TfaPreference
  >();
  const [phone, setPhone] = useState("");
  const [consentChecked, setConsentChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [created, setCreated] = useState(false);
  const [displayHotmailWarning, setDisplayHotmailWarning] = useState<boolean>(
    false
  );

  // Effects

  // NOTE: Currently the SignUp page is not a page of its own, it is tied up to the Login page
  // As such, there is only entry point into the SignUp flow. Any refresh when the user is in
  // the SignUp page will cause the loginMode state to reset back to login mode, losing any
  // fields entered during an incomplete sign up
  useEffect(() => {
    analytics?.cdp()?.disconnectUser();
    analytics?.cdp()?.identifyAnonymousUser();
    analytics?.cdp()?.trackEvent(UserActionEvent.SignUpInitiated);
    analytics?.web()?.trackEvent(UserActionEvent.SignUpInitiated);
  }, []);

  // Errors

  const phoneNumberRequired = tfaPreference === "sms";

  const firstNameError = firstName.length === 0 ? t.requiredFieldError : null;
  const lastNameError = lastName.length === 0 ? t.requiredFieldError : null;
  const emailError = !EMAIL_REGEX.test(email) ? t.invalidEmailError : null;
  const passwordError = !passwordValid ? t.invalidPasswordError : null;
  const phoneError = !PHONE_NUMBER_REGEX.test(phone)
    ? t.phoneNumberError
    : null;
  const hasError =
    firstNameError !== null ||
    lastNameError !== null ||
    emailError !== null ||
    passwordError !== null ||
    (phoneNumberRequired && phoneError !== null);
  const disabled =
    firstName.length === 0 ||
    lastName.length === 0 ||
    email.length === 0 ||
    password.length === 0 ||
    !tfaPreference ||
    (phoneNumberRequired && phone.length === 0) ||
    !consentChecked ||
    (submitted && hasError);

  // Handlers

  const submit = () => {
    setSubmitted(true);
    if (hasError) return;

    signup();
  };

  const onClickTfaCheckbox = (pref: TfaPreference) => {
    if (pref !== tfaPreference) {
      setTfaPreference(pref);

      const eventProperies = { method: pref };

      analytics
        ?.cdp()
        ?.trackEvent(UserActionEvent.MFASetupInitiated, eventProperies);
      analytics
        ?.web()
        ?.trackEvent(UserActionEvent.MFASetupInitiated, eventProperies);
    } else {
      setTfaPreference(undefined);
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    if (value.toLowerCase().includes("hotmail")) setDisplayHotmailWarning(true);
    else if (displayHotmailWarning) setDisplayHotmailWarning(false);
  };

  // Network

  const signup = async () => {
    setLoading(true);

    const searchParams = new URLSearchParams(search);
    const invitationToken = searchParams.get("invitationToken") || undefined;

    try {
      await Axios.post(ENDPOINTS.SIGNUP, {
        email,
        firstName,
        lastName,
        password,
        tfaPreference,
        phone,
        invitationToken,
      });

      if (tfaPreference !== undefined) {
        const eventProperties = { method: tfaPreference };
        analytics
          ?.cdp()
          ?.trackEvent(UserActionEvent.MFASetupCompleted, eventProperties);
        analytics
          ?.web()
          ?.trackEvent(UserActionEvent.MFASetupCompleted, eventProperties);
      }

      analytics?.cdp()?.trackEvent(UserActionEvent.SignUpCompleted);
      analytics?.web()?.trackEvent(UserActionEvent.SignUpCompleted);
      analytics?.cdp()?.identifyUser(firstName, email, phone, "", "", "");

      setCreated(true);
    } finally {
      setLoading(false);
    }
  };

  const resendConfirmation = async () => {
    setLoading(true);

    try {
      await Axios.put(ENDPOINTS.RESEND_CONFIRMATION, {
        email,
      });
      NotificationManager.success(`${t.confirmationResent}${email}`);
    } finally {
      setLoading(false);
    }
  };

  // Rendering

  const renderAccountCreated = () => {
    return (
      <div>
        <div className={styles.imgHolder}>
          <img src={signupIconSrc} />
        </div>
        <h2>{t.accountCreated}</h2>
        <p>
          {t.emailConfirmationMessage}. {t.confirmationResent}
          <b>{email}</b>.
        </p>

        <Button loading={loading} type="secondary" onClick={resendConfirmation}>
          {t.resend}
        </Button>
      </div>
    );
  };

  if (created) return renderAccountCreated();

  return (
    <div>
      <div className={styles.imgHolder}>
        <img src={signupIconSrc} />
      </div>
      <h2>{t.signUpHeader}</h2>

      <div className="input-holder">
        <label>
          {t.firstNameLabel}{" "}
          <span className="disclaimer">({t.firstNameWarning})</span>
        </label>
        <Field
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          type="text"
          onPressEnter={submit}
          error={submitted ? firstNameError : null}
        />
      </div>
      <div className="input-holder">
        <label>
          {t.lastNameLabel}{" "}
          <span className="disclaimer">({t.lastNameWarning})</span>
        </label>
        <Field
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          type="text"
          onPressEnter={submit}
          error={submitted ? lastNameError : null}
        />
      </div>
      <div className="input-holder">
        <label>{t.emailLabel}</label>
        <Field
          value={email}
          onChange={handleEmailChange}
          type="email"
          onPressEnter={submit}
          error={submitted ? emailError : null}
          moreInfo={displayHotmailWarning ? hotmailWarningMessage : undefined}
        />
      </div>
      <div className="input-holder">
        <label>{t.passwordLabel}</label>
        <PasswordValidator password={password} setValid={setPasswordValid} />
        <Field
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          onPressEnter={submit}
          error={submitted ? passwordError : null}
        />
        <p className="disclaimer">{t.passwordWarning}</p>
      </div>
      <div className="input-holder">
        <label>{t.tfaLabel}</label>
        <p
          className="disclaimer"
          dangerouslySetInnerHTML={{ __html: t.tfaContext }}
        />
        <Checkbox
          checked={tfaPreference === "email"}
          onChange={() => onClickTfaCheckbox("email")}
        >
          {t.byEmail}
        </Checkbox>
        <Checkbox
          checked={tfaPreference === "sms"}
          onChange={() => onClickTfaCheckbox("sms")}
        >
          {t.bySms}
        </Checkbox>
      </div>
      {tfaPreference === "sms" && (
        <div className="input-holder">
          <label>{t.phoneNumberLabel}</label>
          <Field
            placeholder={t.phoneNumberPlaceholder}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="phone"
            onPressEnter={submit}
            error={submitted ? phoneError : null}
          />
        </div>
      )}

      <hr />

      <div className="input-holder">
        <Checkbox
          className={styles.consent}
          checked={consentChecked}
          onChange={setConsentChecked}
        >
          <span dangerouslySetInnerHTML={{ __html: t.consentMessage }} />
        </Checkbox>
      </div>

      <a onClick={setLoginMode}>{t.alreadyHasAccountLink}</a>

      <Button disabled={disabled} loading={loading} onClick={submit}>
        {t.signUpButtonText}
      </Button>
      {submitted && hasError && (
        <p className="has-error disclaimer">{t.formErrors}</p>
      )}
    </div>
  );
};

export default SignupForm;

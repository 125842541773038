import { Button, Modal } from "components";
import useLanguage from "hooks/language";
import { DeliveryStatusOptions } from "models/delivery";
import { useState } from "react";
import { useSelectedDelivery, useUpdateDelivery } from "../../../utils/context";
import styles from "./modals.module.css";

export const CancelDeliveryModal = ({
  cancelModalVisible,
  setCancelModalVisible,
}: {
  cancelModalVisible: boolean;
  setCancelModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
  const delivery = useSelectedDelivery();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const updateDelivery = useUpdateDelivery();
  const {
    translations: {
      prepSection: {
        prepDelivery: {
          active: { cancelDelivery: t },
        },
      },
    },
  } = useLanguage();

  const onCancel = async () => {
    setIsLoading(true);
    delivery &&
      (await updateDelivery(
        {
          status: DeliveryStatusOptions.CANCELED,
        },
        delivery.id
      ));
    setCancelModalVisible(false);
    setIsLoading(false);
  };

  return (
    <Modal
      title={t.title}
      visible={cancelModalVisible}
      onVisibilityChange={setCancelModalVisible}
    >
      <p className={styles.content}>{t.description}</p>
      <div className="dual-button-holder">
        <Button loading={isLoading} onClick={onCancel}>
          {t.cancel}
        </Button>
      </div>
    </Modal>
  );
};

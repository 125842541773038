import React, { useEffect, useState } from "react";
import styles from "./time-picker.module.css";
import { Loader } from "react-feather";

const MiN_HEIGHT = 48;

interface TimePickerProps {
  labelText?: string;
  availableTimeSlots?: string[];
  onSelectTime?: (time: string | undefined) => void;
  className?: string;
  maxHeight: number;
}

const TimePicker: React.FunctionComponent<TimePickerProps> = ({
  labelText,
  availableTimeSlots,
  onSelectTime,
  className,
  maxHeight,
}: TimePickerProps) => {
  // States

  const [selectedTime, setSelectedTime] = useState<string>();

  // Effect

  useEffect(() => {
    setSelectedTime(undefined);
  }, [availableTimeSlots]);

  useEffect(() => {
    if (onSelectTime && selectedTime) onSelectTime(selectedTime);
  }, [selectedTime]);

  // Handlers

  const onPickerClick = () => {
    setSelectedTime(undefined);
  };

  const onTimeClick = (time: string) => {
    setSelectedTime(time);
  };

  // Rendering

  const loading = !availableTimeSlots;
  const disabled = availableTimeSlots && availableTimeSlots.length === 0;
  const active =
    availableTimeSlots && availableTimeSlots.length > 0 && !selectedTime;

  let rootClass = `${styles.holder}`;
  if (className) rootClass += ` ${className}`;
  if (active) rootClass += ` ${styles.isActive}`;
  if (disabled) rootClass += ` ${styles.disabled}`;
  if (loading) rootClass += ` ${styles.loading}`;

  return (
    <div
      className={rootClass}
      style={{ maxHeight: active ? maxHeight : MiN_HEIGHT }}
    >
      <div className={styles.selectedTime} onClick={onPickerClick}>
        {selectedTime ? (
          selectedTime
        ) : !loading ? (
          labelText
        ) : (
          <Loader className="spinorama" />
        )}
      </div>

      <div className={styles.list}>
        {(availableTimeSlots || []).map((time) => (
          <div
            key={time}
            className={styles.time}
            onClick={() => onTimeClick(time)}
          >
            {time.replace(":", "h")}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimePicker;

import { geekblue, gray, green, red } from "@ant-design/colors";
import { DeliveryStatus, DeliveryStatusOptions } from "models/delivery";
import { CSSProperties } from "react";

export const mapStatusToTagStyle = (status: DeliveryStatus): CSSProperties => {
  const borderBase = "1px solid";
  switch (status) {
    case DeliveryStatusOptions.PENDING ||
      DeliveryStatusOptions.REQUEST_IN_PROGRESS:
      return { color: gray[8], border: `${borderBase} ${gray[8]}` };
    case DeliveryStatusOptions.CONFIRMED:
      return { color: green[7], border: `${borderBase} ${green[7]}` };
    case DeliveryStatusOptions.CANCELED || DeliveryStatusOptions.REJECTED:
      return { color: red[7], border: `${borderBase} ${red[7]}` };
    case DeliveryStatusOptions.DELIVERED:
      return { color: geekblue[6], border: `${borderBase} ${geekblue[6]}` };
    default:
      return { color: gray[8], border: `${borderBase} ${gray[8]}` };
  }
};

export const mapStatusToDotStyle = (status: DeliveryStatus): CSSProperties => {
  switch (status) {
    case DeliveryStatusOptions.PENDING ||
      DeliveryStatusOptions.REQUEST_IN_PROGRESS:
      return { backgroundColor: gray[8] };
    case DeliveryStatusOptions.CONFIRMED:
      return { backgroundColor: green[8] };
    case DeliveryStatusOptions.CANCELED || DeliveryStatusOptions.REJECTED:
      return { backgroundColor: red[7] };
    case DeliveryStatusOptions.DELIVERED:
      return { backgroundColor: geekblue[6] };
    default:
      return { backgroundColor: gray[8] };
  }
};

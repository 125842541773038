import React, { useState, useEffect } from "react";
import Axios, { AxiosError } from "axios";
import { NotificationManager } from "react-notifications";
import useLanguage from "../../hooks/language";
import useAuthUser from "../../hooks/auth-user";
import {
  Button,
  Footer,
  DateSelect,
  Field,
  Header,
  Select,
  Option,
  Modal,
} from "../../components";
import { ConfirmPhoneNumberModal } from "../../modals";
import { Selection } from "../../components/select";
import {
  SELF_DEFINED_GENDER_OPTIONS,
  GENDER_OPTIONS,
  PersonalInfo,
  TFA_PREFERENCE_OPTIONS,
} from "../../utils/personal-info-constants";
import {
  TfaPreference,
  DEFAULT_BIRTHDATE,
  DEFAULT_RAMQEXPDATE,
} from "../../models/user";
import { PHONE_NUMBER_REGEX, isValidRAMQ } from "../../utils/validation";
import ENDPOINTS from "../../utils/endpoints";
import useAnalytics from "hooks/analytics";
import { UserActionEvent } from "utils/analytics/events";

const NOW = new Date();
NOW.setDate(NOW.getDate() - 1);

const ProfilePage: React.FunctionComponent = () => {
  const [authUser, setAuthUser] = useAuthUser();
  const { translations, language } = useLanguage();
  const t = translations.profileSection;
  const tModal = translations.phoneConfirmationModal;
  const [analytics] = useAnalytics();

  // States

  const [firstName, setFirstName] = useState(authUser?.firstName ?? "");
  const [lastName, setLastName] = useState(authUser?.lastName ?? "");
  const [phone, setPhone] = useState(authUser?.phone ?? "");
  const [birthDate, setBirthDate] = useState(
    authUser && authUser.birthDate
      ? new Date(authUser.birthDate)
      : DEFAULT_BIRTHDATE
  );
  const [ramqNumber, setRamqNumber] = useState(authUser?.ramqNumber ?? "");
  const [ramqExpDate, setRamqExpDate] = useState(
    authUser && authUser.ramqExpDate
      ? new Date(authUser.ramqExpDate)
      : DEFAULT_RAMQEXPDATE
  );
  const [ramqExpDateErr, setRamqExpDateErr] = useState(undefined);
  const [profession, setProfession] = useState(authUser?.profession ?? "");
  const [address, setAddress] = useState(authUser?.address ?? "");
  const [city, setCity] = useState(authUser?.city ?? "");
  const [zipCode, setZipCode] = useState(authUser?.zipCode ?? "");
  const [selfDefinedGender, setSelfDefinedGender] = useState(
    authUser?.selfDefinedGender
  );
  const [bornGender, setBornGender] = useState(authUser?.bornGender);
  const [tfaPreference, setTfaPreference] = useState(authUser?.tfaPreference);
  const [phoneNumberConfirmed, setPhoneNumberConfirmed] = useState(
    authUser?.phoneNumberConfirmed ?? false
  );

  const [isUpdatingEmailPref, setIsUpdatingEmailPref] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSendingResetEmail, setIsSendingResetEmail] = useState(false);

  const [confirmationModalVisible, setConfirmationModalVisible] = useState(
    false
  );

  // Validation

  const formIsLoading = authUser === null;

  const isDirty =
    authUser !== null &&
    (authUser.firstName !== firstName ||
      authUser.lastName !== lastName ||
      authUser.phone !== phone ||
      authUser.birthDate !== birthDate.toISOString() ||
      authUser.ramqNumber !== ramqNumber ||
      authUser.ramqExpDate !== ramqExpDate.toISOString().split("T")[0] ||
      authUser.profession !== profession ||
      authUser.address !== address ||
      authUser.city !== city ||
      authUser.zipCode !== zipCode ||
      authUser.selfDefinedGender !== selfDefinedGender ||
      authUser.bornGender !== bornGender ||
      authUser.tfaPreference !== tfaPreference);

  const phoneError =
    authUser !== null && !PHONE_NUMBER_REGEX.test(phone)
      ? t.phoneNumberError
      : null;
  const ramqError =
    ramqNumber && ramqNumber !== "" && !isValidRAMQ(ramqNumber)
      ? t.ramqNumberError
      : null;
  const hasError = phoneError !== null || ramqError !== null;

  // Utils

  const resetForm = () => {
    if (!authUser) return;

    setFirstName(authUser.firstName);
    setLastName(authUser.lastName);
    setBirthDate(
      authUser.birthDate ? new Date(authUser.birthDate) : DEFAULT_BIRTHDATE
    );
    setPhone(authUser.phone);
    setRamqNumber(authUser.ramqNumber);
    setRamqExpDate(
      authUser.ramqExpDate
        ? new Date(authUser.ramqExpDate)
        : DEFAULT_RAMQEXPDATE
    );
    setProfession(authUser.profession);
    setAddress(authUser.address);
    setCity(authUser.city);
    setZipCode(authUser.zipCode);
    setSelfDefinedGender(authUser.selfDefinedGender);
    setBornGender(authUser.bornGender);
    setTfaPreference(authUser.tfaPreference);
    setPhoneNumberConfirmed(authUser.phoneNumberConfirmed);
  };

  // Effects

  useEffect(resetForm, [authUser]);

  useEffect(() => {
    if (authUser && phone !== "") {
      setPhoneNumberConfirmed(
        authUser.phone === phone && authUser.phoneNumberConfirmed
      );
    }
  }, [authUser, phone]);

  // Network

  const updateUser = async () => {
    if (!authUser || hasError) return;
    const body = {
      firstName,
      lastName,
      birthDate: birthDate.toISOString(),
      phone,
      ramqNumber: ramqNumber === "" ? null : ramqNumber,
      ramqExpDate: ramqExpDate,
      profession,
      address,
      city,
      zipCode,
      selfDefinedGender,
      bornGender,
      tfaPreference,
    };
    setIsSaving(true);
    try {
      const { data } = await Axios.put(ENDPOINTS.ME, body);
      setRamqExpDateErr(undefined);

      analytics?.cdp()?.trackEvent(UserActionEvent.ProfileUpdated);
      analytics?.web()?.trackEvent(UserActionEvent.ProfileUpdated);

      setAuthUser({ ...authUser, ...data });
    } catch (err: AxiosError | any) {
      if (Axios.isAxiosError(err)) {
        if (err.response?.data.type == "ramq_date_expired")
          setRamqExpDateErr(err.response?.data.message);
      }
      console.log(err);
    } finally {
      setIsSaving(false);
    }
  };

  const updateEmailPref = async () => {
    if (!authUser) return;

    setIsUpdatingEmailPref(true);
    await Axios.put(ENDPOINTS.ME, {
      emailRemindersDisabled: !authUser.emailRemindersDisabled,
    });
    setAuthUser({
      ...authUser,
      emailRemindersDisabled: !authUser.emailRemindersDisabled,
    });
    setIsUpdatingEmailPref(false);
  };

  const requestPasswordResetEmail = async () => {
    if (!authUser) return;

    setIsSendingResetEmail(true);

    try {
      await Axios.put(ENDPOINTS.REQUEST_RESET_PASSWORD_EMAIL, {
        email: authUser.email,
      });
      NotificationManager.success(t.emailSent);
    } finally {
      setIsSendingResetEmail(false);
    }
    setAuthUser({
      ...authUser,
      emailRemindersDisabled: !authUser.emailRemindersDisabled,
    });
    setIsUpdatingEmailPref(false);
  };

  // Handlers

  const onSelectSelfDefinedGender = (
    selection: Selection | undefined | null | Selection[]
  ) => {
    if (!selection || Array.isArray(selection)) return;
    setSelfDefinedGender(selection.value as string);
  };

  const onSelectBornGender = (
    selection: Selection | undefined | null | Selection[]
  ) => {
    if (!selection || Array.isArray(selection)) return;
    setBornGender(selection.value as string);
  };

  const onSelectTfaPreference = (
    selection: Selection | undefined | null | Selection[]
  ) => {
    if (!selection || Array.isArray(selection)) return;
    setTfaPreference(selection.value as TfaPreference);
  };

  const onConfirmPhoneNumber = () => {
    setConfirmationModalVisible(false);
    setPhoneNumberConfirmed(true);
  };

  // Rendering

  const renderPersonalInfoOption = (info: PersonalInfo) => {
    return (
      <Option value={info.val} display={info[language]}>
        {info[language]}
      </Option>
    );
  };

  const selfDefinedGenderInfo = selfDefinedGender
    ? SELF_DEFINED_GENDER_OPTIONS.find(({ val }) => val === selfDefinedGender)
    : null;
  const selfDefinedGenderSelection: Selection | null =
    selfDefinedGender && selfDefinedGenderInfo
      ? {
          key: selfDefinedGender,
          value: selfDefinedGender,
          display: selfDefinedGenderInfo[language],
        }
      : null;

  const bornGenderInfo = bornGender
    ? GENDER_OPTIONS.find(({ val }) => val === bornGender)
    : null;
  const bornGenderSelection: Selection | null =
    bornGender && bornGenderInfo
      ? {
          key: bornGender,
          value: bornGender,
          display: bornGenderInfo[language],
        }
      : null;

  const tfaPreferenceInfo = tfaPreference
    ? TFA_PREFERENCE_OPTIONS.find(({ val }) => val === tfaPreference)
    : null;
  const tfaPreferenceSelection: Selection | null =
    tfaPreference && tfaPreferenceInfo
      ? {
          key: tfaPreference,
          value: tfaPreference,
          display: tfaPreferenceInfo[language],
        }
      : null;

  const shoudConfirmPhone = !phoneNumberConfirmed && phoneError === null;
  const hasToConfirmPhone = shoudConfirmPhone && tfaPreference === "sms";
  return (
    <div>
      {/* Phone confirmation modal */}
      <Modal
        title={tModal.title}
        visible={confirmationModalVisible}
        onVisibilityChange={setConfirmationModalVisible}
      >
        <ConfirmPhoneNumberModal
          visible={confirmationModalVisible}
          phoneNumber={phone}
          onConfirm={onConfirmPhoneNumber}
        />
      </Modal>

      <div className="page">
        <Header />

        <div className="container">
          <h2>{t.personalInformationHeader}</h2>

          <div className="row">
            <div className="input-holder fifty">
              <label>{t.firstNameLabel}</label>
              <Field
                maxlength={255}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                loading={formIsLoading}
              />
            </div>

            <div className="input-holder fifty">
              <label>{t.lastNameLabel}</label>
              <Field
                maxlength={255}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                loading={formIsLoading}
              />
            </div>
          </div>

          <div className="row">
            <div className="input-holder forty">
              <label>{t.phoneNumberLabel}</label>
              <Field
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                loading={formIsLoading}
                error={phoneError}
              />
              {shoudConfirmPhone && (
                <a
                  className="link"
                  onClick={() => setConfirmationModalVisible(true)}
                >
                  {t.confirmPhoneNumber}
                </a>
              )}
            </div>

            <div className="input-holder sixty">
              <label>{t.birthDateLabel}</label>
              <DateSelect
                date={birthDate}
                maxDate={NOW}
                onChange={setBirthDate}
                disabled={formIsLoading}
              />
            </div>
          </div>

          <div className="row">
            <div className="input-holder thirty">
              <label>{t.ramqNumberLabel}</label>
              <Field
                value={ramqNumber}
                onChange={(e) => setRamqNumber(e.target.value)}
                disabled={authUser?.ramqNumber != null}
                loading={formIsLoading}
                maxlength={14}
                error={ramqError}
              />
            </div>

            <div className="input-holder thirty">
              <label>{t.ramqNumberExpirationLabel}</label>
              <DateSelect
                date={ramqExpDate}
                onChange={setRamqExpDate}
                disabled={formIsLoading}
                error={ramqExpDateErr}
                showDaySelect={false}
              />
            </div>

            <div className="input-holder thirty">
              <label>{t.jobLabel}</label>
              <Field
                value={profession}
                onChange={(e) => setProfession(e.target.value)}
                loading={formIsLoading}
                maxlength={100}
              />
            </div>
          </div>

          <div className="row">
            <div className="input-holder thirty">
              <label>{t.addressLabel}</label>
              <Field
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                loading={formIsLoading}
                maxlength={200}
              />
            </div>

            <div className="input-holder thirty">
              <label>{t.cityPlaceHolder}</label>
              <Field
                value={city}
                onChange={(e) => setCity(e.target.value)}
                loading={formIsLoading}
                maxlength={100}
              />
            </div>

            <div className="input-holder thirty">
              <label>{t.zipCodePlaceHolder}</label>
              <Field
                value={zipCode}
                maxlength={6}
                onChange={(e) => setZipCode(e.target.value)}
                loading={formIsLoading}
              />
            </div>
          </div>

          <div className="row">
            <div className="input-holder fifty">
              <label>{t.selfDefinedGender}</label>
              <Select
                loading={formIsLoading}
                selection={selfDefinedGenderSelection}
                placeholder={t.choose}
                onSelect={onSelectSelfDefinedGender}
              >
                {SELF_DEFINED_GENDER_OPTIONS.map(renderPersonalInfoOption)}
              </Select>
            </div>

            <div className="input-holder fifty">
              <label>{t.bornGender}</label>
              <Select
                loading={formIsLoading}
                selection={bornGenderSelection}
                placeholder={t.choose}
                onSelect={onSelectBornGender}
              >
                {GENDER_OPTIONS.map(renderPersonalInfoOption)}
              </Select>
            </div>
          </div>

          <div className="row">
            <div className="input-holder fifty">
              <label>{t.tfaPreference}</label>
              <Select
                loading={formIsLoading}
                selection={tfaPreferenceSelection}
                placeholder={t.choose}
                onSelect={onSelectTfaPreference}
              >
                {TFA_PREFERENCE_OPTIONS.map(renderPersonalInfoOption)}
              </Select>
              {hasToConfirmPhone && (
                <a
                  onClick={() => setConfirmationModalVisible(true)}
                  className="has-error text-right link"
                >
                  {t.phoneNumberMustBeConfirmed}
                </a>
              )}
            </div>

            <div className="input-holder fifty">
              <label>{t.followUpEmails}</label>
              <Button
                type="secondary"
                onClick={updateEmailPref}
                loading={isUpdatingEmailPref}
                disabled={formIsLoading}
              >
                {authUser && authUser.emailRemindersDisabled
                  ? t.subscribe
                  : t.unsubscribe}
              </Button>
            </div>
          </div>

          <br />
          <br />

          <div className="dual-button-holder flex-end">
            <Button
              disabled={!isDirty || isSaving}
              type="secondary"
              onClick={resetForm}
            >
              {t.cancel}
            </Button>
            <Button
              disabled={!isDirty || hasError || hasToConfirmPhone}
              onClick={updateUser}
              loading={isSaving}
            >
              {t.save}
            </Button>
          </div>

          <br />
          <br />

          <h2>{t.password}</h2>
          {!isSendingResetEmail && (
            <a
              onClick={requestPasswordResetEmail}
              className="link"
              type="secondary"
            >
              {t.requestPassword}
            </a>
          )}
          {isSendingResetEmail && <p className="link">{t.sending}</p>}
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ProfilePage;

import React, { useState } from "react";
import Axios from "axios";
import { NotificationManager } from "react-notifications";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { X } from "react-feather";

import styles from "./payment.module.css";
import useLanguage from "../../../../hooks/language";
import useAuthUser from "../../../../hooks/auth-user";
import { formatCoupon } from "../../../../utils/format";
import ENDPOINTS from "../../../../utils/endpoints";
import { Button, Field } from "../../../../components";

const elementOptions = {
  style: {
    base: {
      fontFamily: '"Poppins" sans-serif',
      fontSize: "16px",
      border: "1px solid #1C1C1C",
      fontWeight: "400",
      color: "#1C1C1C",
    },
  },
};

interface PaymentFormProps {
  onCompletePayment?: () => void;
}

const PaymentForm: React.FunctionComponent<PaymentFormProps> = ({
  onCompletePayment,
}: PaymentFormProps) => {
  const { translations, language } = useLanguage();
  const [authUser] = useAuthUser();
  const stripe = useStripe();
  const elements = useElements();
  const t = translations.paymentSection;

  // States

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [couponName, setCouponName] = useState("");
  const [validatingCoupon, setValidatingCoupon] = useState(false);
  const [coupon, setCoupon] = useState<null | any>(null);

  // Validation

  const enabled =
    cardHolderName.length > 0 &&
    address.length > 0 &&
    city.length > 0 &&
    postalCode.length > 0;

  // Network

  const validateCoupon = async () => {
    setValidatingCoupon(true);

    try {
      const { data } = await Axios.get(ENDPOINTS.VALIDATE_COUPON(couponName));
      setCoupon(data);
      setCouponName("");
    } finally {
      setValidatingCoupon(false);
    }
  };

  const payQuestionnaire = async (token: any) => {
    if (!authUser || !authUser.lastUserQuestionnaire) return;

    const body = {
      token: token.id,
      couponId: coupon ? coupon.id : undefined,
    };

    try {
      const { data } = await Axios.put(
        ENDPOINTS.PAY_USER_QUESTIONNAIRE(authUser.lastUserQuestionnaire.id),
        body
      );
      authUser.lastUserQuestionnaire = data;
      if (onCompletePayment) onCompletePayment();
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handlers

  const onSubmit = async () => {
    if (!stripe || !elements) return;

    setIsSubmitting(true);

    const cardNumberElement = elements.getElement(CardNumberElement);

    if (!cardNumberElement) return;

    const { token, error } = await stripe.createToken(cardNumberElement, {
      name: cardHolderName,
      address_zip: postalCode,
      address_line1: address,
      address_city: city,
    });

    if (error) {
      NotificationManager.error(error.message);
      console.error(error);
      setIsSubmitting(false);
      return;
    }

    if (token) {
      await payQuestionnaire(token);
      setIsSubmitting(false);
    }
  };

  // Rendering

  return (
    <div>
      <div className="row">
        <div className="input-holder hundred">
          <label>{t.cardHolderName}</label>
          <Field
            value={cardHolderName}
            onChange={(e) => setCardHolderName(e.target.value)}
          />
        </div>
      </div>

      <div className="row">
        <div className="input-holder forty">
          <label>{t.cardNumber}</label>
          <div className="stripe-element-wrapper">
            <CardNumberElement options={elementOptions} />
          </div>
        </div>
        <div className="input-holder thirty">
          <label>{t.cardExpirationDate}</label>
          <div className="stripe-element-wrapper">
            <CardExpiryElement options={elementOptions} />
          </div>
        </div>
        <div className="input-holder thirty">
          <label>{t.cardSecurityCode}</label>
          <div className="stripe-element-wrapper">
            <CardCvcElement options={elementOptions} />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="input-holder thirty">
          <label>{t.cardHolderAddress}</label>
          <Field value={address} onChange={(e) => setAddress(e.target.value)} />
        </div>
        <div className="input-holder thirty">
          <label>{t.cardHolderCity}</label>
          <Field value={city} onChange={(e) => setCity(e.target.value)} />
        </div>
        <div className="input-holder thirty">
          <label>{t.cardHolderZipCode}</label>
          <Field
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          />
        </div>
      </div>

      <div className="row">
        <div className="input-holder eighty">
          <label>{t.applyCouponLabel}</label>
          <Field
            value={couponName}
            onPressEnter={validateCoupon}
            onChange={(e) => setCouponName(e.target.value)}
          />
        </div>
        <div className="input-holder twenty">
          <Button
            loading={validatingCoupon}
            onClick={validateCoupon}
            className={styles.applyButton}
            disabled={couponName.length === 0}
          >
            {t.applyCouponButton}
          </Button>
        </div>
      </div>

      {coupon && (
        <div className={styles.couponHolder}>
          <span>{coupon.name}</span>
          <b>
            {t.couponDiscountLabel}: {formatCoupon(coupon, language)}
          </b>
          <X onClick={() => setCoupon(null)} />
        </div>
      )}

      <br />

      <p className="disclaimer">{t.privacyMessage}</p>
      <p className="disclaimer">
        <b>{t.delayDisclaimer}</b>
      </p>

      <div className="dual-button-holder flex-end">
        <Button
          loading={isSubmitting}
          onClick={onSubmit}
          disabled={!stripe || !enabled || !authUser}
        >
          {t.payButtonText}
        </Button>
      </div>
    </div>
  );
};

export default PaymentForm;

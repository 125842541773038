import { Timeline } from "antd";
import useAuthUser from "hooks/auth-user";
import useLanguage from "hooks/language";
import { formatDate, isFuture } from "../../utils/utils";
import styles from "./prep-status-history.module.css";

export const PrEPStatusHistory = () => {
  const [user] = useAuthUser();
  const {
    translations: {
      prepSection: {
        prepStatus: { active: t },
      },
    },
  } = useLanguage();

  const timelineItems =
    user?.prepStatuses?.map(
      ({ created_at, status, pharmacist, eligibilityEndDate }) => {
        const isFutureDate = isFuture(eligibilityEndDate);
        const date = formatDate(created_at);

        return {
          label: (
            <span className={isFutureDate ? styles.current : ""}>
              {isFutureDate ? t.today : date}
            </span>
          ),
          children: (
            <>
              <span className={isFutureDate ? styles.current : ""}>
                {t.status[status]}
              </span>
              {pharmacist && (
                <span className={styles.pharmacist}>
                  <br />
                  {t.by} {pharmacist?.last_name}, {pharmacist?.first_name}
                </span>
              )}
            </>
          ),
        };
      }
    ) ?? [];

  const latest = user?.lastPrepStatus;
  if (latest && isFuture(latest.eligibilityEndDate)) {
    timelineItems.unshift({
      label: (
        <span className={styles.future}>
          {latest.eligibilityEndDate && formatDate(latest.eligibilityEndDate)}
        </span>
      ),
      children: (
        <span className={styles.future}>
          {t.expiration} {t.status[latest.status]}
        </span>
      ),
    });
  }

  return <Timeline mode="left" items={timelineItems} />;
};

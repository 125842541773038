import React, { useState } from "react";
import Axios from "axios";
import { NotificationManager } from "react-notifications";
import useLanguage from "../../hooks/language";
import { Button } from "../../components";
import Appointment from "../../models/appointment";
import ENDPOINTS from "../../utils/endpoints";
import useAnalytics from "hooks/analytics";
import { UserActionEvent } from "utils/analytics/events";

interface CancelAppointmentModalProps {
  onClose?: () => void;
  onConfirm?: (appointment: Appointment) => void;
  appointment?: Appointment;
}

const CancelAppointmentModal: React.FunctionComponent<CancelAppointmentModalProps> = ({
  onClose,
  onConfirm,
  appointment,
}: CancelAppointmentModalProps) => {
  const { translations } = useLanguage();
  const t = translations.appointmentsSection;
  const [analytics] = useAnalytics();

  // States

  const [loading, setLoading] = useState(false);

  // Network

  const cancelAppointment = async () => {
    if (!appointment) return;

    setLoading(true);

    try {
      await Axios.delete(ENDPOINTS.APPOINTMENT(appointment.id));

      if (onConfirm) {
        onConfirm(appointment);
      }

      analytics
        ?.cdp()
        ?.trackEvent(UserActionEvent.ScreeningAppoinmentCancelled);
      analytics
        ?.web()
        ?.trackEvent(UserActionEvent.ScreeningAppoinmentCancelled);

      NotificationManager.success(t.cancellationModal.appointmentCanceled);
    } finally {
      setLoading(false);
    }
  };

  // Rendering

  return (
    <div>
      <p>{t.cancellationConfirmModal.content}</p>
      <div className="dual-button-holder">
        <Button onClick={onClose} disabled={loading}>
          {t.cancellationModal.exitButtonText}
        </Button>
        <Button onClick={cancelAppointment} type="secondary" loading={loading}>
          {t.cancellationModal.confirmButtonText}
        </Button>
      </div>
    </div>
  );
};

export default CancelAppointmentModal;

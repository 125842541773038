import { MouseEvent } from "react";
import { Link } from "react-router-dom";
import useLanguage from "hooks/language";
import ROUTES from "utils/routes";
import appointmentsIcon from "assets/images/appointments-icon.svg";
import screeningIcon from "assets/images/screening-icon.svg";
import prepIcon from "assets/images/prep-icon.svg";
import { useUpcomingAppointmentInfo } from "./useUpcomingAppointmentInfo";
import styles from "./home.module.css";
import useAnalytics from "hooks/analytics";
import { UserActionEvent } from "utils/analytics/events";

type Props = {
  onScreeningLinkClick: (e: MouseEvent) => void;
};

export const Nav = ({ onScreeningLinkClick }: Props) => {
  const {
    translations: {
      homePage: { subheaderTab: tNav, tooltipTab: tTooltip },
      sectionSelection: tWelcome,
    },
  } = useLanguage();

  const [analytics] = useAnalytics();

  const {
    date,
    isLastAppointmentScheduled,
    hasFollowUp,
  } = useUpcomingAppointmentInfo();

  const isScreeningDisabled = isLastAppointmentScheduled || hasFollowUp;

  const welcomeString = isLastAppointmentScheduled
    ? tWelcome.welcomeTextReminder
    : tWelcome.welcomeTextBasic;

  const onCreateAppointmentClicked = () => {
    analytics?.cdp()?.trackEvent(UserActionEvent.ScreeningRequestedInitiated);
    analytics?.web()?.trackEvent(UserActionEvent.ScreeningRequestedInitiated);
  };

  return (
    <div className={styles.navigation}>
      <p>{`${welcomeString} ${date}`}</p>

      <div>
        <Link to={ROUTES.APPOINTMENTS}>
          <button>
            <img alt="" src={appointmentsIcon} />
            <h3>{tNav.myAppointmentTitle}</h3>
          </button>
        </Link>

        <Link
          to={ROUTES.SCREENING}
          className={isScreeningDisabled ? styles.disabledLink : ""}
          onClick={
            isScreeningDisabled
              ? (e: MouseEvent) => e.preventDefault()
              : onScreeningLinkClick
          }
        >
          <button onClick={onCreateAppointmentClicked}>
            <div
              disabled-tool-tip={
                isLastAppointmentScheduled
                  ? tTooltip.screening
                  : tTooltip.phoneAppointment
              }
            >
              <img alt="" src={screeningIcon} />
            </div>
            <h3>{tNav.screeningTitle}</h3>
          </button>
        </Link>

        <Link to={ROUTES.PREP}>
          <button>
            <img alt="" src={prepIcon} />
            <h3>{tNav.myPrepTitle}</h3>
          </button>
        </Link>
      </div>
    </div>
  );
};

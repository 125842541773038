import Tabs from "components/tabs";
import useAuthUser from "hooks/auth-user";
import useLanguage from "hooks/language";
import { PREP_STATUS } from "models/prep-status";
import { MyPrEPContextProvider } from "./utils/context";
import { PrEPStatusTab } from "./components/prep-status-tab";
import { PrEPDeliveryTab } from "./delivery-tab";

export const MyPrEP = () => {
  const [user] = useAuthUser();
  const {
    translations: { prepSection: t },
  } = useLanguage();

  return (
    <MyPrEPContextProvider>
      <Tabs
        tabs={[
          { title: t.prepStatus.title, content: <PrEPStatusTab /> },
          {
            title: t.prepDelivery.title,
            content: <PrEPDeliveryTab />,
            disabled: user?.lastPrepStatus?.status !== PREP_STATUS.ELIGIBLE,
            reason: t.prepDelivery.disabledPopover,
          },
        ]}
      />
    </MyPrEPContextProvider>
  );
};

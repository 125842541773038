import { DatePicker, Form, Radio } from "antd";
import { Button } from "components";
import useAuthUser from "hooks/auth-user";
import useLanguage from "hooks/language";
import { deliveryLimitDate, DeliveryQuantityOptions } from "models/delivery";
import { useEffect, useState } from "react";
import { useCreateOnDemandDelivery } from "../../utils/context";
import styles from "./current-plan.module.css";

export const OnDemandForm = (): JSX.Element => {
  // States
  const [submittable, setSubmittable] = useState<boolean>(false);

  // Hooks
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [user] = useAuthUser();
  const createOnDemandDelivery = useCreateOnDemandDelivery();
  const {
    translations: {
      prepSection: {
        prepDelivery: {
          active: { onDemand: t },
        },
      },
    },
  } = useLanguage();

  // Validate fields
  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  // Handlers
  const onSubmit = async () => await createOnDemandDelivery(values);

  return (
    <Form form={form}>
      <Form.Item
        name="prepQty"
        label={t.quantityLabel}
        rules={[{ required: true, message: t.quantityError }]}
      >
        <Radio.Group>
          <Radio value={DeliveryQuantityOptions.ONE_MONTH}>
            {t.quantityOptions[DeliveryQuantityOptions.ONE_MONTH]}
          </Radio>
          <Radio value={DeliveryQuantityOptions.THREE_MONTHS}>
            {t.quantityOptions[DeliveryQuantityOptions.THREE_MONTHS]}
          </Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="date"
        label={t.dateLabel}
        rules={[{ required: true, message: t.dateError }]}
      >
        <DatePicker
          placeholder={t.datePlaceholder}
          className={styles.datePicker}
          disabledDate={(date) =>
            date.isBefore(deliveryLimitDate()) ||
            date.isAfter(user?.lastPrepStatus?.eligibilityEndDate)
          }
          showNow={false}
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          onClick={onSubmit}
          disabled={!submittable}
          className={styles.submitButton}
        >
          {t.submitBtnLabel}
        </Button>
      </Form.Item>
      <span className={styles.quantityDisclaimer}>{t.quantityDisclaimer}</span>
    </Form>
  );
};

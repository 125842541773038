import { red } from "@ant-design/colors";
import { EnvironmentOutlined } from "@ant-design/icons";
import { Row, Space } from "antd";
import Button from "components/button";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import useAuthUser from "hooks/auth-user";
import useLanguage from "hooks/language";
import { getScreeningForPrelibDelivery } from "models/appointment";
import {
  Delivery,
  DeliveryStatusOptions,
  extractMostRecentDERBasedOnStatuses,
  getEntityToConsider,
  isDeliveryWithinLimitHours,
  isInThePast,
  requestInProgress,
} from "models/delivery";
import { DeliveryEditRequestStatuses } from "models/delivery-edit-request";
import { DeliveryDestinationOptions } from "models/delivery-settings";
import { useState } from "react";
import styled from "styled-components";
import { ActionButtonsGroup } from "../../../components/action-buttons-group";
import { DeliveryTag } from "../../../components/delivery-tag";
import {
  useSetEditAddressModalVisible,
  useSetSelectedDelivery,
  useUpdateDelivery,
} from "../../../utils/context";
import styles from "./collapse-item.module.css";

export const collapseItem = (delivery: Delivery, key: string) => {
  // Hooks
  const setEditAddressModalVisible = useSetEditAddressModalVisible();
  const updateDelivery = useUpdateDelivery();
  const setSelectedDelivery = useSetSelectedDelivery();
  const [authUser] = useAuthUser();
  const {
    translations: {
      prepSection: {
        prepDelivery: {
          active: { deliverySummary: t },
        },
      },
    },
    language,
  } = useLanguage();
  if (!authUser) return null;

  // helper functions
  const renderAddress = () => {
    if (
      authUser &&
      delivery.destination === DeliveryDestinationOptions.PRELIB_PICK_UP
    ) {
      const associatedScreeningAppointment = getScreeningForPrelibDelivery(
        authUser,
        delivery
      );
      return `${associatedScreeningAppointment?.clinic.address} (${associatedScreeningAppointment?.clinic.name}) `;
    }
    if (delivery.destination === DeliveryDestinationOptions.PHARMACY_PICK_UP) {
      return t.pharmacieDuVillage;
    }
    if (!delivery.address && !delivery.city && !delivery.zipCode) return "-";
    else
      return `${delivery.address ?? ""} ${delivery.city ?? ""} ${
        delivery.zipCode ?? ""
      }`;
  };

  // Components
  const PanelHeader = () => {
    const renderDate = () => {
      const prendingDER = requestInProgress(delivery);
      return (
        <div>
          {prendingDER
            ? dayjs(prendingDER?.new?.date).format("DD/MM/YYYY")
            : dayjs(delivery.date).format("DD/MM/YYYY")}
          <div className={prendingDER ? styles.dateDisabled : ""}>
            {prendingDER?.new?.date
              ? dayjs(delivery.date).format("DD/MM/YYYY")
              : ""}
          </div>
        </div>
      );
    };

    return (
      <div className={styles.panelHeader}>
        <div className={styles.date}>{renderDate()}</div>
        <div className={styles.status}>
          <DeliveryTag status={delivery.status} />
        </div>
        <span className={styles.address}>{renderAddress()}</span>
      </div>
    );
  };

  const PickUpAtPrelibSection = () => {
    const associatedScreeningAppointment = getScreeningForPrelibDelivery(
      authUser,
      delivery
    );
    if (!associatedScreeningAppointment) return null;

    return (
      <>
        <p className="disclaimer">{t.prelibPickUpDisclaimer}</p>
        <Row align="middle" className={styles.prelibRow}>
          <EnvironmentOutlined style={{ fontSize: "40px" }} />
          <Space
            direction="vertical"
            size="small"
            style={{ marginLeft: "1rem" }}
          >
            <span style={{ fontWeight: 700 }}>
              {t.pickUpAtPrelibOn}
              {dayjs(associatedScreeningAppointment.datetime)
                .locale(language)
                .format("D MMMM YYYY, HH[h]mm")}
            </span>
            <span>{renderAddress()}</span>
          </Space>
        </Row>
      </>
    );
  };

  const PendingDelivery = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // User action handler: Accept or Reject the delivery
    const onAction = async (action: "ACCEPT" | "REJECT") => {
      setIsLoading(true);
      await updateDelivery(
        {
          status:
            action === "ACCEPT"
              ? DeliveryStatusOptions.REQUEST_IN_PROGRESS
              : DeliveryStatusOptions.CANCELED,
        },
        delivery.id
      );
      setIsLoading(false);
    };

    if (!delivery) return null;
    return (
      <div className={styles.deliveryBodyContainer}>
        <div className={styles.customRow}>
          <b>{t.quantityLabel}</b>
          <span>
            {delivery.prepQty
              ? t["quantity"][delivery.prepQty]
              : t["quantity"][delivery.deliveryProfile]}
          </span>
        </div>
        <div className={styles.customRow}>
          <b>{t.destinationLabel}</b>
          <span>{t["destination"][delivery.destination]}</span>
        </div>

        {delivery.destination === DeliveryDestinationOptions.PRELIB_PICK_UP && (
          <PickUpAtPrelibSection />
        )}

        {delivery.destination === DeliveryDestinationOptions.RESIDENCE ||
          (delivery.destination === DeliveryDestinationOptions.POST_PICK_UP && (
            <div style={{ display: "inline-block" }}>
              <b>{t.addressLabel}</b>
              <span>{renderAddress()}</span>
              <StyledLink
                onClick={() => {
                  setEditAddressModalVisible(true);
                  setSelectedDelivery(delivery);
                }}
              >
                {t.modifier}
              </StyledLink>
            </div>
          ))}

        <div className={styles.buttonsContainer}>
          <Button
            type="primary"
            loading={isLoading}
            onClick={() => onAction("ACCEPT")}
          >
            {t.confirm}
            <sup> 1</sup>
          </Button>
          <StyledRedButton onClick={() => onAction("REJECT")} type="secondary">
            {t.refuse}
            <sup> 2</sup>
          </StyledRedButton>
        </div>
        <div>
          <p className="disclaimer">
            <sup>1 </sup>
            {t.prelibPickUpInfo1}
          </p>
          <p className="disclaimer">
            <sup>2 </sup>
            {t.prelibPickUpInfo2}
          </p>
        </div>
      </div>
    );
  };

  const DeliverySummaryView = () => {
    const { language } = useLanguage();
    dayjs.locale(language);

    const formatedStatusUpdatedAt = delivery.statusUpdatedByPharmacistAt
      ? dayjs(delivery.statusUpdatedByPharmacistAt).format("DD MMMM YYYY")
      : null;

    const deliveredAt = dayjs(delivery.date).format("DD MMMM YYYY");

    const mostRecentResolvedDER = extractMostRecentDERBasedOnStatuses(
      delivery,
      [
        DeliveryEditRequestStatuses.ACCEPTED,
        DeliveryEditRequestStatuses.REJECTED,
      ]
    );

    const entityToConsider = mostRecentResolvedDER
      ? getEntityToConsider(delivery, mostRecentResolvedDER)
      : delivery;

    let description;
    switch (delivery.status) {
      case DeliveryStatusOptions.REQUEST_IN_PROGRESS:
        description = (
          <>
            {isInThePast(delivery) ? "" : <b>{t.pendingDeliveryDescription}</b>}
          </>
        );
        break;
      case DeliveryStatusOptions.CONFIRMED:
        description = (
          <>
            <b>{t.confirmedAt}</b> {formatedStatusUpdatedAt}
          </>
        );
        break;
      case DeliveryStatusOptions.REJECTED:
        description = (
          <>
            <b>{t.rejectedAt}</b>
            {formatedStatusUpdatedAt}
          </>
        );
        break;
      case DeliveryStatusOptions.DELIVERED:
        description = (
          <>
            <b>{t.deliveredAt}</b>
            {deliveredAt}
          </>
        );
        break;
      default:
        description = "";
    }

    const isDeliveryConfirmedAndHasResolvedDER =
      delivery.status === DeliveryStatusOptions.CONFIRMED &&
      mostRecentResolvedDER;

    return (
      <div className={styles.deliveryBodyContainer}>
        {isDeliveryConfirmedAndHasResolvedDER && (
          <div className={styles.customRow}>
            <i>
              <span>{t.der[mostRecentResolvedDER.status]}</span>
            </i>
            {mostRecentResolvedDER.status ===
              DeliveryEditRequestStatuses.REJECTED &&
              ` ${t.requestedDate} ${dayjs(
                mostRecentResolvedDER.new.date
              ).format("DD/MM/YYYY")}. `}
          </div>
        )}
        <div className={styles.customRow}>{description}</div>
        {entityToConsider.justification && (
          <div className={styles.customRow}>
            <b>{t.justificationLabel}</b>
            <span>{entityToConsider.justification}</span>
          </div>
        )}
        <div className={styles.customRow}>
          <b>{t.quantityLabel}</b>
          <span>
            {delivery.prepQty
              ? t["quantity"][delivery.prepQty]
              : t["quantity"][delivery.deliveryProfile]}
          </span>
        </div>
        <div className={styles.customRow}>
          <b>{t.destinationLabel}</b>
          <span>{t["destination"][delivery.destination]}</span>
        </div>
        <div className={styles.customRow}>
          <b>{t.addressLabel}</b>
          <span>{renderAddress()}</span>
          {delivery.status === DeliveryStatusOptions.REQUEST_IN_PROGRESS ||
            (delivery.status === DeliveryStatusOptions.CONFIRMED &&
              (delivery.destination ===
              DeliveryDestinationOptions.PRELIB_PICK_UP ? (
                <PickUpAtPrelibSection />
              ) : (
                delivery.destination !==
                  DeliveryDestinationOptions.PHARMACY_PICK_UP && (
                  <div style={{ display: "inline-block" }}>
                    <StyledLink
                      onClick={() => {
                        setEditAddressModalVisible(true);
                        setSelectedDelivery(delivery);
                      }}
                    >
                      {t.modify}
                    </StyledLink>
                  </div>
                )
              )))}
        </div>
      </div>
    );
  };

  let content;
  if (delivery.status === DeliveryStatusOptions.PENDING) {
    if (isDeliveryWithinLimitHours(delivery)) content = <DeliverySummaryView />;
    else {
      content = <PendingDelivery />;
    }
  } else {
    content = <DeliverySummaryView />;
  }

  return {
    key,
    label: <PanelHeader />,
    children: content,
    extra: <ActionButtonsGroup delivery={delivery} />,
  };
};

const StyledLink = styled.a`
  text-decoration: underline !important;
  margin-left: 1rem;
  font-weight: 700;
`;

const StyledRedButton = styled(Button)`
  color: ${red[8]} !important;
  border-color: ${red[8]} !important;
`;

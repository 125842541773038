import { Button, Select, Tooltip } from "antd";
import useLanguage from "hooks/language";
import {
  Delivery,
  DeliveryStatusOptions,
  isDeliveryWithinLimitHours as _isDeliveryWithinLimitHours,
  NonEditableDeliveryStatuses,
  NON_EDITABLE_STATUSES,
} from "models/delivery";
import {
  CalendarBlank,
  CheckCircle,
  IconProps,
  MapPin,
  TrashSimple,
} from "phosphor-react";
import { ComponentType, FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import {
  useSetEditAddressModalVisible,
  useSetSelectedDelivery,
  useUpdateDelivery,
} from "../../utils/context";
import { CancelDeliveryModal } from "./modals/cancel-delivery";
import { EditDateModal } from "./modals/edit-date";

type ActionButtonsGroup = {
  delivery: Delivery;
};

type ActionButton = {
  tooltipTitle: string;
  Icon: ComponentType<IconProps>;
  onClick?: () => void;
  disabled?: boolean;
};

export const ActionButtonsGroup: FunctionComponent<ActionButtonsGroup> = ({
  delivery,
}: ActionButtonsGroup): JSX.Element => {
  // States
  const [editDateModalVisible, setEditDateModalVisible] = useState<boolean>(
    false
  );
  const [cancelModalVisible, setCancelModalVisible] = useState<boolean>(false);
  const [
    isDeliveryWithinLimitHours,
    setIsDeliveryWithinLimitHours,
  ] = useState<boolean>(false);

  // Hooks
  const updateDelivery = useUpdateDelivery();
  const setSelectedDelivery = useSetSelectedDelivery();
  const setEditAddressModalVisible = useSetEditAddressModalVisible();
  const {
    translations: {
      prepSection: {
        prepDelivery: {
          active: { actionTooltip: t },
        },
      },
    },
  } = useLanguage();

  useEffect(() => {
    if (delivery.date) {
      setIsDeliveryWithinLimitHours(_isDeliveryWithinLimitHours(delivery));
    }
  }, [delivery.date]);

  // Event handlers
  const onActivateDelivery = async () => {
    await updateDelivery(
      { status: DeliveryStatusOptions.REQUEST_IN_PROGRESS },
      delivery.id
    );
  };

  // Derived states
  const isActionDisabled =
    isDeliveryWithinLimitHours ||
    NON_EDITABLE_STATUSES.includes(
      delivery.status as NonEditableDeliveryStatuses
    );

  const shouldActivateDeliveryToEdit =
    delivery.status === DeliveryStatusOptions.CANCELED &&
    !isDeliveryWithinLimitHours;

  // Components
  const ActionButton = ({
    tooltipTitle,
    Icon,
    onClick,
    disabled,
  }: ActionButton) => (
    <Tooltip title={tooltipTitle}>
      <Button
        type="text"
        icon={<Icon size={20} />}
        disabled={disabled}
        onClick={(e) => {
          setSelectedDelivery(delivery);
          e.stopPropagation();
          onClick && onClick();
        }}
      />
    </Tooltip>
  );

  return (
    <>
      <div style={{ display: "flex" }}>
        <ActionButton
          tooltipTitle={
            shouldActivateDeliveryToEdit ? t.activateDeliveryToEdit : t.editDate
          }
          Icon={CalendarBlank}
          disabled={isActionDisabled}
          onClick={() => setEditDateModalVisible(true)}
        />
        <ActionButton
          tooltipTitle={
            shouldActivateDeliveryToEdit
              ? t.activateDeliveryToEdit
              : t.editDestination
          }
          Icon={MapPin}
          disabled={isActionDisabled}
          onClick={() => setEditAddressModalVisible(true)}
        />
        {delivery.status === DeliveryStatusOptions.CANCELED ? (
          <ActionButton
            tooltipTitle={t.activate}
            Icon={CheckCircle}
            disabled={isDeliveryWithinLimitHours}
            onClick={onActivateDelivery}
          />
        ) : (
          <ActionButton
            tooltipTitle={t.cancel}
            disabled={isActionDisabled}
            Icon={TrashSimple}
            onClick={() => setCancelModalVisible(true)}
          />
        )}
      </div>
      {editDateModalVisible && (
        <EditDateModal
          editDateModalVisible={editDateModalVisible}
          setEditDateModalVisible={setEditDateModalVisible}
        />
      )}
      {cancelModalVisible && (
        <CancelDeliveryModal
          cancelModalVisible={cancelModalVisible}
          setCancelModalVisible={setCancelModalVisible}
        />
      )}
    </>
  );
};

export const StyledSelect = styled(Select)`
  height: 2.8rem;
  .ant-select-selector {
    border-radius: 0px;
  }
`;

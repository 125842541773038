export interface DeliverySettings {
  frequency: DeliveryFrequency;
  destination: DeliveryDestination;
  firstDeliveryDate: string;
  pickUpAtAppointment: boolean | null;
  address: string | null;
  city: string | null;
  zipCode: string | null;
  communicationPreference: string;
}

export const DeliveryFrequencyOptions = {
  ONE_MONTH: "1_MONTH",
  THREE_MONTHS: "3_MONTHS",
  ON_DEMAND: "ON_DEMAND",
  ON_PAUSE: "ON_PAUSE",
} as const;

export type DeliveryFrequency = keyof typeof DeliveryFrequencyOptions;

export const DeliveryDestinationOptions = {
  RESIDENCE: "RESIDENCE",
  POST_PICK_UP: "POST_PICK_UP",
  PHARMACY_PICK_UP: "PHARMACY_PICK_UP",
  PRELIB_PICK_UP: "PRELIB_PICK_UP",
} as const;

export type DeliveryDestination = keyof typeof DeliveryDestinationOptions;

import React, { useState } from "react";
import Axios from "axios";
import { Calendar, MapPin } from "react-feather";
import { DateTime } from "luxon";
import { NotificationManager } from "react-notifications";
import { Card } from "components/card";
import { Button, MedicalResultRow, PersonalRecommendations } from "..";
import Appointment, {
  isScheduled,
  isCanceled,
  isCanceledForStaffAbsence,
  isMissed,
  isCompleted,
  canEdit,
  hasReceivedResults,
  isMissedAndCannotRetake,
  getPhoneAppointmentToSchedule,
  hasPendingResults,
  isUpcoming,
} from "../../models/appointment";
import { sortChosenMedicalTests } from "../../models/chosen-medical-test";
import useLanguage from "../../hooks/language";
import ENDPOINTS from "../../utils/endpoints";

import styles from "./appointment-card.module.css";

interface ScreeningAppointmentCardProps {
  appointment: Appointment;
  onEditClick?: () => void;
  onRescheduleClick?: () => void;
  onRetakeQuestionnaireClick?: () => void;
  onSchedulePhoneAppointmentClick?: () => void;
  onScheduleRetakeClick?: () => void;
  onFeedbackClick?: () => void;
}

const ScreeningAppointmentCard: React.FunctionComponent<ScreeningAppointmentCardProps> = ({
  appointment,
  onEditClick,
  onRescheduleClick,
  onRetakeQuestionnaireClick,
  onSchedulePhoneAppointmentClick,
  onScheduleRetakeClick,
  onFeedbackClick,
}: ScreeningAppointmentCardProps) => {
  const { translations, language } = useLanguage();
  const tCard = translations.appointmentsSection.appointmentCard;
  const tResults = translations.resultsSection;

  // State

  const [isLoading, setIsLoading] = useState(false);

  // Network

  const rejectRetake = async () => {
    setIsLoading(true);

    try {
      await Axios.put(ENDPOINTS.REJECT_APPOINTMENT_RETAKE(appointment.id));
      appointment.status = "RETAKE_REJECTED";
      NotificationManager.success(
        translations.notification.retakeRejectedAppointment
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Rendering

  const {
    datetime,
    chosenMedicalTests,
    suggestionsAreApproved,
    canTakeSatisfactionQuestionnaire,
    userQuestionnaire,
  } = appointment;

  // Local date time string
  // TODO: Not hardcode the the timezone to mtl
  const date = DateTime.fromISO(datetime).setZone("America/Montreal");
  const localeDateString = date
    .setLocale(language)
    .toLocaleString(DateTime.DATETIME_MED);
  const day = date.day;
  const shortMonth = date.setLocale(language).toFormat("MMM");
  const clinic = appointment.clinic.name;
  const clinicAddress = appointment.clinic.address;

  // Scheduled
  const scheduled = isScheduled(appointment);

  // Canceled
  const canceled = isCanceled(appointment);
  const canceledForStaffAbsence = isCanceledForStaffAbsence(appointment);
  const cancelationIndicator = canceled
    ? canceledForStaffAbsence && appointment.shouldRetake
      ? tCard.staffCancellationIndicator
      : tCard.cancellationIndicator
    : null;

  // Missed
  const missed = isMissed(appointment);
  const missedAnCannotRetake = isMissedAndCannotRetake(appointment);

  // Completed
  const completed = isCompleted(appointment);

  // Results pending
  const awaitingResults =
    !hasReceivedResults(appointment) &&
    !canceled &&
    !isUpcoming(appointment) &&
    !missed &&
    !missedAnCannotRetake;

  // Can edit
  const _canEdit = canEdit(appointment);
  const canRetakeQuestionnaire = _canEdit && !suggestionsAreApproved;

  // Phone appointment to schedule
  const phoneAppointmentToSchedule = getPhoneAppointmentToSchedule(appointment);

  // Titles
  const title = `${
    cancelationIndicator ? `${cancelationIndicator} - ` : ""
  }${tCard.dateIndicator(localeDateString)}`;
  let testsTitle = tCard.medicalTestsIndicator;
  if (completed) testsTitle = tCard.resultsIndicator;

  const canceledStyle = canceled || missedAnCannotRetake ? styles.canceled : "";

  return (
    <Card>
      <Card.Header>
        <MapPin className={`${missed ? "urgent" : ""} ${canceledStyle}`} />
        <div>
          <h2>{title}</h2>
          <p>
            {clinic} -{" "}
            <a
              className={styles.address}
              target="_blank"
              rel="noreferrer"
              href={`https://www.google.com/maps/search/${clinicAddress}`}
            >
              {clinicAddress}
            </a>
          </p>
        </div>
      </Card.Header>

      <Card.Body>
        <div className={styles.left}>
          {/* Date indicator */}
          <div className={`${styles.dateHolder} ${canceledStyle}`}>
            <Calendar />
            <span className={styles.day}>{day}</span>
            <span className={styles.month}>{shortMonth}</span>
          </div>
          <div className={styles.clinicHolder}>
            <h4>{clinic}</h4>
            <a
              className={styles.address}
              target="_blank"
              rel="noreferrer"
              href={`https://www.google.com/maps/search/${clinicAddress}`}
            >
              {clinicAddress}
            </a>
          </div>

          {/* Buttons */}
          {_canEdit && (
            <Button type="secondary" onClick={onEditClick}>
              {tCard.editButtonText}
            </Button>
          )}
          {canRetakeQuestionnaire && (
            <Button type="secondary" onClick={onRetakeQuestionnaireClick}>
              {tCard.retakeQuestionnaire}
            </Button>
          )}
          {canceledForStaffAbsence && appointment.shouldRetake && (
            <Button type="secondary" onClick={onRescheduleClick}>
              {tCard.rescheduleButton}
            </Button>
          )}
        </div>

        <div className={styles.right}>
          {/* Results section */}
          {phoneAppointmentToSchedule && (
            <div className={styles.infoHolder}>
              <p>{tResults.schedulePhoneAppointment.title}</p>
              <Button
                type="secondary"
                onClick={onSchedulePhoneAppointmentClick}
              >
                {tResults.schedulePhoneAppointment.scheduleButton}
              </Button>
            </div>
          )}

          <p>
            <b>{testsTitle}</b>
            {awaitingResults &&
              ` - ${tCard.resultsNotReadyLine1} ${tCard.resultsNotReadyLine2}`}
            {missedAnCannotRetake && ` - ${tCard.missedAppointmentMessage}`}
            {missed && (
              <span
                className={missed ? "urgent" : ""}
              >{` - ${tCard.missedMessage}`}</span>
            )}
          </p>

          {sortChosenMedicalTests(chosenMedicalTests, language).map((t) => {
            if (t.medicalTest.name === "creatinine") return null;
            return <MedicalResultRow key={t.id} test={t} canceled={canceled} />;
          })}
        </div>
      </Card.Body>

      <Card.Footer>
        {/* Results recommendations */}
        {canceled && (
          <p>
            <b>{tResults.recommendationsWhenCancel}</b>
          </p>
        )}

        {scheduled && (
          <p>
            <b>{tResults.recommendationsWhenNotDone}</b>
          </p>
        )}

        {awaitingResults && (
          <p>
            <b>
              {tResults.resultsNotReadyLine1}. {tResults.resultsNotReadyLine2}
              {". "}
              {tResults.recommendationsWhenNoResult}
            </b>
          </p>
        )}

        {hasPendingResults(appointment) && (
          <div className={styles.infoHolder}>
            <p>{tResults.pendingInfo.header}</p>
            <p>{tResults.pendingInfo.explanation}</p>
          </div>
        )}

        {appointment.shouldRetake && !isCanceledForStaffAbsence(appointment) && (
          <div className={styles.infoHolder}>
            <p>
              <strong>{tResults.retakeInfo.row1_1}</strong>
              {tResults.retakeInfo.row1_2}
            </p>
            <p>{tResults.retakeInfo.row2_1}</p>
            <p className="dual-button-holder">
              <Button onClick={onScheduleRetakeClick} disabled={isLoading}>
                {tResults.retakeInfo.retakeButton}
              </Button>
              <Button
                type="secondary"
                onClick={rejectRetake}
                loading={isLoading}
              >
                {tResults.retakeInfo.rejectRetakeButton}
              </Button>
            </p>
          </div>
        )}

        {hasReceivedResults(appointment) && (
          <p>
            <b>{tResults.recommendations}</b>
          </p>
        )}

        <PersonalRecommendations userQuestionnaire={userQuestionnaire} />

        {canTakeSatisfactionQuestionnaire && (
          <Button type="secondary" onClick={onFeedbackClick}>
            {tResults.giveUsYourFeedback}
          </Button>
        )}
      </Card.Footer>
    </Card>
  );
};

export default ScreeningAppointmentCard;

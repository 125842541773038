import {
  CustomerIOAnalytics,
  ICustomerIOConfig,
} from "./customer-data-platform/customerIO";
import {
  IRudderstackConfig,
  RudderStackAnalytics,
} from "./customer-data-platform/rudderstack";
import { IPlausibleConfig, PlausibleAnalytics } from "./web/plausible";
import {
  IAnalyticsService,
  ICDPAnalyticsService,
  IWebAnalyticsService,
} from ".";

function isRudderstackConfig(config: {
  [key: string]: any;
}): config is IRudderstackConfig {
  return (
    Object.keys(config).length === 2 &&
    !!config.writeKey &&
    !!config.dataplaneURL
  );
}

function isCustomerIOConfig(config: {
  [key: string]: any;
}): config is ICustomerIOConfig {
  return (
    Object.keys(config).length === 3 &&
    !!config.writeKey &&
    !!config.cdnURL &&
    typeof config.isProxyCdnURL === "boolean"
  );
}

function isPlausibleConfig(config: {
  [key: string]: any;
}): config is IPlausibleConfig {
  return (
    Object.keys(config).length === 3 &&
    !!config.apiHostURL &&
    !!config.domainURL &&
    typeof config.trackLocalhost === "boolean"
  );
}

function createCDPAnalyticsService(config: {
  [key: string]: any;
}): ICDPAnalyticsService | null {
  if (isRudderstackConfig(config)) {
    const analytics = new RudderStackAnalytics(
      config.writeKey,
      config.dataplaneURL
    );

    analytics.init();

    return analytics;
  } else if (isCustomerIOConfig(config)) {
    return new CustomerIOAnalytics(
      config.writeKey,
      config.cdnURL,
      config.isProxyCdnURL
    );
  }

  console.warn("Customer Data Platform Analytics is not configured");

  return null;
}

function createWebAnalyticsService(config: {
  [key: string]: any;
}): IWebAnalyticsService | null {
  if (isPlausibleConfig(config)) {
    return new PlausibleAnalytics(
      config.apiHostURL,
      config.domainURL,
      config.trackLocalhost
    );
  }

  console.warn("Web Analytics is not configured");

  return null;
}

export function createAnalyticsService(config: {
  cdp: { [key: string]: any };
  web: { [key: string]: any };
}): IAnalyticsService {
  const cdpAnalytics = createCDPAnalyticsService(config.cdp);
  const webAnalytics = createWebAnalyticsService(config.web);

  return {
    cdp: () => cdpAnalytics,
    web: () => webAnalytics,
  };
}

import React, { useEffect, useState } from "react";
import Axios from "axios";
import { QuestionnaireForm } from "../../../components";
import { buildInfoQuestionnaire } from "../../../utils/personal-info-constants";
import useAuthUser from "../../../hooks/auth-user";
import useLanguage from "../../../hooks/language";
import Questionnaire from "../../../models/questionnaire";
import ENDPOINTS from "../../../utils/endpoints";
import useAnalytics from "hooks/analytics";
import { UserActionEvent } from "utils/analytics/events";
interface PersonalInfoProps {
  onSubmit: () => void;
}

const extractBodyFromQuestionnaire = (questionnaire: Questionnaire) => {
  const ramqQuestion = questionnaire.questions.find(
    ({ key }) => key === "ramqNumber"
  );
  const ramqExpDate = questionnaire.questions.find(
    ({ key }) => key === "ramqExpDate"
  );
  const birthDateQuestion = questionnaire.questions.find(
    ({ key }) => key === "birthDate"
  );
  const phoneQuestion = questionnaire.questions.find(
    ({ key }) => key === "phone"
  );
  const professionQuestion = questionnaire.questions.find(
    ({ key }) => key === "profession"
  );
  const addressQuestion = questionnaire.questions.find(
    ({ key }) => key === "address"
  );
  const cityQuestion = questionnaire.questions.find(
    ({ key }) => key === "city"
  );
  const zipCodeQuestion = questionnaire.questions.find(
    ({ key }) => key === "zipCode"
  );
  const countryQuestion = questionnaire.questions.find(
    ({ key }) => key === "country"
  );
  const selfDefinedGenderQuestion = questionnaire.questions.find(
    ({ key }) => key === "selfDefinedGender"
  );
  const bornGenderQuestion = questionnaire.questions.find(
    ({ key }) => key === "bornGender"
  );

  return {
    ramqNumber:
      ramqQuestion?.answer.rawValue === ""
        ? null
        : ramqQuestion?.answer.rawValue,
    ramqExpDate:
      ramqQuestion?.answer.rawValue === ""
        ? null
        : ramqExpDate?.answer.rawValue,
    birthDate: birthDateQuestion?.answer.rawValue,
    phone: phoneQuestion?.answer.rawValue,
    profession: professionQuestion?.answer.rawValue,
    address: addressQuestion?.answer.rawValue,
    city: cityQuestion?.answer.rawValue,
    zipCode: zipCodeQuestion?.answer.rawValue,
    country: countryQuestion?.answer.rawValue,
    selfDefinedGender: selfDefinedGenderQuestion?.answer.rawValue,
    bornGender: bornGenderQuestion?.answer.rawValue,
  };
};

const PersonalInfo: React.FunctionComponent<PersonalInfoProps> = ({
  onSubmit,
}: PersonalInfoProps) => {
  const [authUser, setAuthUser] = useAuthUser();
  const { translations } = useLanguage();
  const t = translations.infoConfirmationSection;
  const [analytics] = useAnalytics();

  // States

  const [questionnaire, setQuestionnaire] = useState<null | Questionnaire>(
    null
  );
  const [isUpdating, setIsUpdating] = useState(false);

  // Effects

  useEffect(() => {
    if (authUser && !questionnaire)
      setQuestionnaire(buildInfoQuestionnaire(authUser));
  }, [authUser]);

  // Network

  const updateUser = async (questionnaire: Questionnaire) => {
    if (!authUser) return;

    const body = extractBodyFromQuestionnaire(questionnaire);
    setIsUpdating(true);

    try {
      const { data } = await Axios.put(ENDPOINTS.ME, body);
      setAuthUser({ ...authUser, ...data });

      analytics
        ?.cdp()
        ?.trackEvent(UserActionEvent.ScreeningInformationSubmitted);
      analytics
        ?.web()
        ?.trackEvent(UserActionEvent.ScreeningInformationSubmitted);

      onSubmit();
    } finally {
      setIsUpdating(false);
    }
  };

  // Rendering
  return (
    <div>
      <h2 className="container">{t.header}</h2>
      {questionnaire && (
        <QuestionnaireForm
          questionnaire={questionnaire}
          onCompleteQuestionnaire={updateUser}
          loading={isUpdating}
        />
      )}
    </div>
  );
};

export default PersonalInfo;

import React, { useState, useEffect } from "react";
import useLanguage from "../../hooks/language";
import { Select, Option } from "../";
import { Selection } from "../select";

import styles from "./date-select.module.css";

interface DateSelectProps {
  className?: string;
  date?: Date;
  error?: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  showDaySelect?: boolean;
  onChange?: (date: Date) => void;
}

// Constants

const NOW = new Date();

const DEFAULT_MIN_DATE = new Date(1900, 0, 1);
const DEFAULT_MAX_DATE = new Date(2050, 0, 1);

const MONTH_LIST = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

// Helpers

const isSameDate = (a: Date, b: Date) =>
  a.getFullYear() === b.getFullYear() &&
  a.getMonth() === b.getMonth() &&
  a.getDay() === b.getDay();

const getYearArray = (minDate: Date, maxDate: Date) => {
  const yearCount = maxDate.getFullYear() - minDate.getFullYear();
  const date = new Date();

  const yearArray = [];
  for (let i = 0; i < yearCount + 1; i++) {
    date.setFullYear(minDate.getFullYear() + i);
    yearArray.push(date.getFullYear());
  }

  return yearArray;
};

const getMonthArray = (selectedDate: Date, minDate: Date, maxDate: Date) => {
  let minMonth = 0;
  if (selectedDate.getFullYear() === minDate.getFullYear()) {
    minMonth = minDate.getMonth();
  }
  let maxMonth = MONTH_LIST.length - 1;
  if (selectedDate.getFullYear() === maxDate.getFullYear()) {
    maxMonth = maxDate.getMonth();
  }
  return MONTH_LIST.slice(minMonth, maxMonth + 1);
};

const getDayArray = (selectedDate: Date, minDate: Date, maxDate: Date) => {
  let minDay = 1;
  if (
    selectedDate.getFullYear() === minDate.getFullYear() &&
    selectedDate.getMonth() === minDate.getMonth()
  ) {
    minDay = minDate.getDate();
  }

  const date = new Date(selectedDate);
  date.setDate(1);
  date.setMonth(date.getMonth() + 1);
  date.setDate(0);

  let maxDay = date.getDate();
  if (
    selectedDate.getFullYear() === maxDate.getFullYear() &&
    selectedDate.getMonth() === maxDate.getMonth()
  ) {
    maxDay = maxDate.getDate();
  }

  const dayArray = [];
  const dayCount = maxDay - minDay;
  for (let i = 0; i <= dayCount; i++) {
    dayArray.push(minDay + i);
  }
  return dayArray;
};

const DateSelect: React.FunctionComponent<DateSelectProps> = ({
  className,
  minDate = DEFAULT_MIN_DATE,
  maxDate = DEFAULT_MAX_DATE,
  disabled = false,
  date = NOW,
  showDaySelect = true,
  error,
  onChange,
}: DateSelectProps) => {
  const { translations } = useLanguage();
  const t = translations;

  // States

  const [_date, _setDate] = useState(date);

  // Effects

  useEffect(() => {
    if (!onChange) return;
    onChange(_date);
  }, [_date]);

  useEffect(() => {
    if (date && !isSameDate(date, _date)) _setDate(date);
  }, [date]);

  // Handlers

  const onSelectYear = (
    selection: Selection | undefined | null | Selection[]
  ) => {
    if (!selection || Array.isArray(selection)) return;
    const { value: year } = selection;

    const newDate = new Date(_date);
    newDate.setFullYear(year as number);

    const currentMonth = _date.getMonth();
    const months = getMonthArray(newDate, minDate, maxDate);
    if (months.indexOf(currentMonth) < 0) {
      newDate.setMonth(months[0]);
    }

    const currentDay = _date.getDate();
    const days = getDayArray(newDate, minDate, maxDate);
    if (days.indexOf(currentDay) < 0) {
      newDate.setDate(days[0]);
    }

    _setDate(newDate);
  };

  const onSelectMonth = (
    selection: Selection | undefined | null | Selection[]
  ) => {
    if (!selection || Array.isArray(selection)) return;
    const { value: month } = selection;

    const newDate = new Date(_date);
    newDate.setMonth(month as number);

    const currentDay = _date.getDate();
    const days = getDayArray(newDate, minDate, maxDate);
    if (days.indexOf(currentDay) < 0) {
      newDate.setDate(days[0]);
    }

    _setDate(newDate);
  };

  const onSelectDay = (
    selection: Selection | undefined | null | Selection[]
  ) => {
    if (!selection || Array.isArray(selection)) return;
    const { value: day } = selection;

    const newDate = new Date(_date);
    newDate.setDate(day as number);
    _setDate(newDate);
  };

  // Rendering

  let rootClass = styles.holder;
  if (className) rootClass += ` ${className}`;
  if (error) rootClass += ` ${styles.hasError}`;
  const years = getYearArray(minDate, maxDate);
  const months = getMonthArray(_date, minDate, maxDate);
  const days = getDayArray(_date, minDate, maxDate);

  const yearSelection: Selection = {
    value: _date.getFullYear(),
    key: _date.getFullYear(),
  };

  const monthSelection: Selection = {
    value: _date.getMonth(),
    key: _date.getMonth(),
    display: t.months[_date.getMonth()],
  };

  const daySelection: Selection = {
    value: _date.getDate(),
    key: _date.getDate(),
  };

  return (
    <div className={rootClass}>
      <div className={styles.inner}>
        <Select
          className={
            showDaySelect ? styles.yearSelect : styles.yearSelectWithoutDate
          }
          selection={yearSelection}
          onSelect={onSelectYear}
          disabled={disabled}
        >
          {years.reverse().map((year) => (
            <Option key={year} value={year}>
              {year}
            </Option>
          ))}
        </Select>
        <Select
          className={
            showDaySelect ? styles.monthSelect : styles.monthSelectWithoutDate
          }
          selection={monthSelection}
          onSelect={onSelectMonth}
          disabled={disabled}
        >
          {months.map((month) => (
            <Option key={month} value={month} display={t.months[month]}>
              {t.months[month]}
            </Option>
          ))}
        </Select>
        {showDaySelect && (
          <Select
            className={styles.daySelect}
            selection={daySelection}
            onSelect={onSelectDay}
            disabled={disabled}
          >
            {days.map((day) => (
              <Option key={day} value={day}>
                {day}
              </Option>
            ))}
          </Select>
        )}
      </div>

      {error && <label className={styles.error}>{error}</label>}
    </div>
  );
};

export default DateSelect;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// There is enough explicitly types variables and functions for the derived
// hooks's types to be evident. Typing them explicitly would result in less clarity.
import dayjs from "dayjs";
import { createContext, useContext, useEffect, useState } from "react";

import useAuthUser from "hooks/auth-user";
import { Delivery, DeliverySettings } from "models";
import { PREP_STATUS } from "models/prep-status";
import * as API from "./api";

type Context = {
  isLoading: boolean;

  settings: DeliverySettings | null;

  nextDelivery: Delivery | null;
  futureDeliveries: Delivery[];
  pastDeliveries: Delivery[];
  setPastDeliveriesYear: React.Dispatch<React.SetStateAction<string>>;

  updateSettings: (settings: Partial<DeliverySettings>) => Promise<void>;
  updateDelivery: (delivery: Partial<Delivery>, id: number) => Promise<void>;
  createOnDemandDelivery: (delivery: Delivery) => Promise<void>;

  editAddressModalVisible: boolean;
  setEditAddressModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  deliveryPreferencesModalVisible: boolean;
  setDeliveryPreferencesModalVisible: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  selectedDelivery: Delivery | null;
  setSelectedDelivery: React.Dispatch<React.SetStateAction<Delivery | null>>;

  stopPrepService: () => Promise<void>;
};

const MyPrEPContext = createContext<Context | undefined>(undefined);

type ContextProps = React.PropsWithChildren<Record<string, unknown>>;

/**
 * This Context is not intended to be used at the root App's level. Instead,
 * it should be used at the "/home/my-prep" page level, for two benefits:
 *
 *  - Because it is lower in the render tree, it only fetches data when needed;
 *  - Because it is at page level, it fetches data only once for all sub-components.
 */
export const MyPrEPContextProvider = ({ children }: ContextProps) => {
  const [user, setAuthUser] = useAuthUser();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [settings, setSettings] = useState<DeliverySettings | null>(null);

  const [nextDelivery, setNextDelivery] = useState<Delivery | null>(null);
  const [futureDeliveries, setFutureDeliveries] = useState<Delivery[]>([]);
  const [pastDeliveries, setPastDeliveries] = useState<Delivery[]>([]);
  const [pastDeliveriesYear, setPastDeliveriesYear] = useState<string>(
    String(dayjs().year())
  );
  const [
    editAddressModalVisible,
    setEditAddressModalVisible,
  ] = useState<boolean>(false);
  const [
    deliveryPreferencesModalVisible,
    setDeliveryPreferencesModalVisible,
  ] = useState<boolean>(false);
  const [selectedDelivery, setSelectedDelivery] = useState<Delivery | null>(
    null
  );

  const updateSettings = async (
    settings: Partial<DeliverySettings>
  ): Promise<void> => {
    setIsLoading(true);
    await API.updateDeliverySettings(settings).then(setSettings);
    setIsLoading(false);
  };

  const createOnDemandDelivery = (payload: Partial<Delivery>): Promise<void> =>
    API.createOnDemandDelivery(payload).then(getAllDeliveries);

  const updateDelivery = async (
    delivery: Partial<Delivery>,
    id: number
  ): Promise<void> => {
    await API.updateDelivery(delivery, id);
    await getAllDeliveries();
  };

  const fetchAndSetDeliveries = async (
    period: "PAST" | "FUTURE",
    date: string
  ) => {
    API.getDeliveries(period, date).then((deliveries: Delivery[]) => {
      if (period === "PAST") {
        setPastDeliveries(deliveries);
      } else if (period === "FUTURE") {
        setNextDelivery(deliveries[0]);
        setFutureDeliveries(deliveries.slice(1));
      }
    });
  };

  const stopPrepService = async () => {
    const prepStatus = await API.revokePrepStatus();
    await API.getDeliverySettings().then(setSettings);
    await getAllDeliveries();
    if (user) setAuthUser({ ...user, lastPrepStatus: prepStatus });
  };

  const getAllDeliveries = async () => {
    const today = dayjs().format("YYYY-MM-DD");
    await fetchAndSetDeliveries("FUTURE", today);
    await fetchAndSetDeliveries("PAST", pastDeliveriesYear);
  };

  // This async function is called in a useEffect so that all needed data is
  // fetched at mount. Add API function calls in there as needed.
  const getDataAtMount = async () => {
    await API.getDeliverySettings().then(setSettings);
    await getAllDeliveries();
    setIsLoading(false);
  };

  // Get data once, at mount
  useEffect(() => {
    if (user?.lastPrepStatus?.status === PREP_STATUS.ELIGIBLE) {
      getDataAtMount();
    }
  }, [user?.lastPrepStatus]);

  useEffect(() => {
    if (pastDeliveriesYear) {
      fetchAndSetDeliveries("PAST", pastDeliveriesYear);
    }
  }, [pastDeliveriesYear]);

  useEffect(() => {
    getAllDeliveries();
  }, [settings]);

  // Not sure this is the best way to go, but I prefer waiting for all data
  // to be fetched instead of giving partial values, which might create an
  // invalid state. This is why I return `undefined` until the loading is done.
  return (
    <MyPrEPContext.Provider
      value={{
        isLoading,
        settings,
        stopPrepService,
        nextDelivery,
        futureDeliveries,
        pastDeliveries,
        updateSettings,
        updateDelivery,
        setPastDeliveriesYear,
        createOnDemandDelivery,
        editAddressModalVisible,
        setEditAddressModalVisible,
        selectedDelivery,
        setSelectedDelivery,
        deliveryPreferencesModalVisible,
        setDeliveryPreferencesModalVisible,
      }}
    >
      {children}
    </MyPrEPContext.Provider>
  );
};

export const useMyPrEP = () => {
  const context = useContext(MyPrEPContext);

  if (context === undefined) {
    throw new Error("useMyPrEP must be used within a MyPrEPContextProvider");
  }

  return context;
};

// Feel free to use `useMyPrEP` instead of these individual hooks if you prefer,
// but I like this pattern better from the point of view of using them and, unless
// they optimized it in a recent version of React, doing it this way avoids
// unnecessary re-renders (for example, if a component only uses `useUpdateSettings`,
// then it won't re-render when only `isLoading` changes).
export const useIsLoading = () => useMyPrEP().isLoading ?? true;
export const useDeliverySettings = () => useMyPrEP().settings;

export const useNextDelivery = () => useMyPrEP().nextDelivery;
export const useFutureDeliveries = () => useMyPrEP().futureDeliveries;
export const usePastDeliveries = () => useMyPrEP().pastDeliveries;

export const useUpdateSettings = () => useMyPrEP().updateSettings;
export const useUpdateDelivery = () => useMyPrEP().updateDelivery;

export const useCreateOnDemandDelivery = () =>
  useMyPrEP().createOnDemandDelivery;

export const useSetPastDeliveriesYears = () =>
  useMyPrEP()?.setPastDeliveriesYear;

export const useEditAddressModalVisible = () =>
  useMyPrEP().editAddressModalVisible;
export const useSetEditAddressModalVisible = () =>
  useMyPrEP().setEditAddressModalVisible;

export const useDeliveryPreferencesModalVisible = () =>
  useMyPrEP().deliveryPreferencesModalVisible;
export const useSetDeliveryPreferencesModalVisible = () =>
  useMyPrEP().setDeliveryPreferencesModalVisible;

export const useSelectedDelivery = () => useMyPrEP().selectedDelivery;
export const useSetSelectedDelivery = () => useMyPrEP().setSelectedDelivery;

export const useStopPrepService = () => useMyPrEP().stopPrepService;

import MedicalTestTranslation from "./medical-test-translation";

export type SampleTestType = "BLOOD" | "URINARY" | "ORAL" | "ANAL";

export default interface MedicalTest {
  id: number;
  name: string;
  medicalTestTranslations: MedicalTestTranslation[];
  sampleType: SampleTestType;
}

// Utils methods

export const getLocaleName = (
  medicalTest: MedicalTest,
  locale: string
): string =>
  medicalTest.medicalTestTranslations.find(({ lang }) => lang === locale)
    ?.value ?? medicalTest.medicalTestTranslations[0].value;

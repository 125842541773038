import React, { useState, useEffect } from "react";
import useLanguage from "../../hooks/language";
import { Field } from "..";
import styles from "./address-form.module.css";

interface AddressFormProps {
  className?: string;
  onChange?: (address: string, city: string, postalCode: string) => void;
}

const AddressForm: React.FunctionComponent<AddressFormProps> = ({
  className,
  onChange,
}: AddressFormProps) => {
  const { translations } = useLanguage();
  const t = translations.addressForm;

  //States
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");

  // Effects

  useEffect(() => {
    if (!onChange) return;
    onChange(address, city, postalCode);
  }, [address, city, postalCode]);
  let rootClass = styles.container;
  if (className) rootClass += ` ${className}`;
  return (
    <div className={rootClass}>
      <div className={styles.inner}>
        <div className={styles.addressField}>
          <label>{t.addressLine}</label>
          <Field value={address} onChange={(e) => setAddress(e.target.value)} />
        </div>

        <div className={styles.cityField}>
          <label>{t.city}</label>
          <Field value={city} onChange={(e) => setCity(e.target.value)} />
        </div>
        <div className={styles.postalCodeField}>
          <div className={styles.postalCode}>
            <label>{t.postalCode}</label>
            <Field
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddressForm;

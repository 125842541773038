import { v5 as uuidv5 } from "uuid";
import { DeterministicID } from "../determnisitic-id/deterministic-id";

// NOTE: randomly generated, hardcoded UUID is used to determine the namespace of a deterministic ID.
// It should never be changed throughout the lifetime of this type, as any change to this UUID value
// will cause ID generations to not be deterministic anymore.
const analyticsUserIDNamesapce = "a34f3c65-6e6d-493b-91e9-6d5e5dae32ee";

export type AnalyticsUserID = DeterministicID<string, "UserID">;

export function generateAnalyticsUserID(email: string): AnalyticsUserID {
  return uuidv5(email, analyticsUserIDNamesapce);
}

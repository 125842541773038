import React from "react";
import ReactDOM from "react-dom";
import Axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { get } from "lodash";
import { BrowserRouter as Router } from "react-router-dom";
import { getTranslations } from "./translations";
import App from "./App";

import "react-notifications/lib/notifications.css";
import "./index.css";

// Axios global configs

Axios.defaults.baseURL =
  process.env.REACT_APP_API_URL || "http://localhost:4000/api";

Axios.interceptors.response.use(
  (res) => res,
  (error) => {
    const errorStatus = get(error, ["response", "data", "status"], 400);
    const errorMsg = get(
      error,
      ["response", "data", "message"],
      getTranslations().notification.unexpectedError
    );

    console.log(errorStatus, errorMsg);
    NotificationManager.error(errorMsg);

    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
    <NotificationContainer />
  </React.StrictMode>,
  document.getElementById("root")
);

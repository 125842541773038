import type { Dayjs } from "dayjs";
import { DateTime } from "luxon";
import React, { useState } from "react";

import type { Selection } from "components/select";

import {
  DeliveryDestination,
  DeliveryDestinationOptions,
  DeliveryFrequency,
  DeliveryFrequencyOptions,
} from "models/delivery-settings";
import useLanguage from "../../../../hooks/language";

/**
 * From an ISO date string, returns a date string like DD/MM/YYYY.
 */
export const formatDate = (date: string) =>
  DateTime.fromISO(date).toFormat("dd/MM/yyyy");

/**
 * Checks if an ISO date string describes a date in the future.
 */
export const isFuture = (date?: string | null): boolean =>
  !!date && DateTime.fromISO(date) > DateTime.now();

/**
 * Returns a JS Date object that is exactly 72 hours in the future.
 */
export const in72Hours = () => DateTime.now().plus({ hours: 72 }).toJSDate();

/**
 * Our custom form items are kind of weird, and the same can be said of the
 * type Selection that gets used all over tthe place. This function takes the
 * output of an `onSelect` of the `Select` component and returns a string.
 */
export const getSelectionKey = (
  selection: Selection | Selection[] | null | undefined
): string | undefined =>
  Array.isArray(selection) ? undefined : selection?.key?.toString();

/**
 * From an antd's DatePicker object (given by Dayjs), returns an ISO date string.
 */
export const getISOString = (date: Dayjs) => date?.toISOString();

/**
 * From an HTML input event (triggered by an input's `onChange`), return the new value.
 */
export const getEventValue = (event: React.ChangeEvent<HTMLInputElement>) =>
  event.currentTarget.value;

type Form = {
  firstDeliveryDate?: string;
  frequency?: DeliveryFrequency;
  destination?: DeliveryDestination;
  address?: string;
  city?: string;
  zipCode?: string;
  province?: string;
};

/**
 * This is not much better than having a bunch of `useState` or a `useReducer`,
 * but it allows the DeliverySettingsForm component to have its inputs handled
 * without too much hassle.
 */
export const useDeliverySettingsForm = () => {
  const [form, setForm] = useState<Form>({
    firstDeliveryDate: undefined,
    frequency: undefined,
    destination: undefined,
    address: undefined,
    city: undefined,
    zipCode: undefined,
    province: undefined,
  });

  const setField = (fieldName: keyof Form) => (value: string | null) =>
    setForm((prev) => ({ ...prev, [fieldName]: value }));

  return { form, setField } as const;
};

export const prepDeliveryDestinationOptions = (
  localizations: ReturnType<typeof useLanguage>
) => [
  {
    value: DeliveryDestinationOptions.RESIDENCE,
    label:
      localizations.translations.prepSection.deliverySettingsForm.destination
        .options.RESIDENCE.title,
    details: null,
  },
  {
    value: DeliveryDestinationOptions.POST_PICK_UP,
    label:
      localizations.translations.prepSection.deliverySettingsForm.destination
        .options.POST_PICK_UP.title,
    details:
      localizations.translations.prepSection.deliverySettingsForm.destination
        .options.POST_PICK_UP.details,
  },
  {
    value: DeliveryDestinationOptions.PHARMACY_PICK_UP,

    label:
      localizations.translations.prepSection.deliverySettingsForm.destination
        .options.PHARMACY_PICK_UP.title,
    details: null,
  },
];

export const prepDeliveryFrequencyOptions = (
  localizations: ReturnType<typeof useLanguage>
) => [
  {
    value: DeliveryFrequencyOptions.ONE_MONTH,
    label:
      localizations.translations.prepSection.deliverySettingsForm.frequency
        .options["1_MONTH"].title,
    details: null,
  },
  {
    value: DeliveryFrequencyOptions.THREE_MONTHS,
    label:
      localizations.translations.prepSection.deliverySettingsForm.frequency
        .options["3_MONTHS"].title,
    details: null,
  },
  {
    value: DeliveryFrequencyOptions.ON_DEMAND,
    label:
      localizations.translations.prepSection.deliverySettingsForm.frequency
        .options.ON_DEMAND.title,
    details:
      localizations.translations.prepSection.deliverySettingsForm.frequency
        .options.ON_DEMAND.details,
  },
  {
    value: DeliveryFrequencyOptions.ON_PAUSE,
    label:
      localizations.translations.prepSection.deliverySettingsForm.frequency
        .options.ON_PAUSE.title,
    details:
      localizations.translations.prepSection.deliverySettingsForm.frequency
        .options.ON_PAUSE.details,
  },
];
